import React, { ChangeEvent } from 'react';

interface FileUploadButtonProps {
  onFileChange: (file: File | null) => void;
}

const FileUploadButton: React.FC<FileUploadButtonProps> = ({ onFileChange }) => {
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    onFileChange(file);
  };

  return (
    <div className="w-full col-span-2 items-start justify-start">
      <label className="cursor-pointer bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
        Payment slip
        <input type="file" className="hidden" onChange={handleFileChange} />
      </label>
    </div>
  );
};

export default FileUploadButton;
