import moment from "moment";
import { iLastResult } from "../../interface/iHome";

const TodayResult = (props:any) => {
    let product:iLastResult = props.product || ["0","0","0","0","0",];
    let productTime = product['product_time'] &&  moment(+product['product_time'] * 1000);

    return (
        <div className="md:col-span-8 sm:col-span-8 col-span-12 px-2 pb-2 result-container">
        <div className="row">
            <div className="md:col-span-12 sm:col-span-7 sm:col-span-12 col-span-12">
                <div className="section-headline text-center">
                    <h3>លទ្ធផលឆ្នោតថ្ងៃនេះ</h3>
                </div>
            </div>
        </div>
        <table className=" w-full bg-red-100 result-table">
            <thead>
                <tr className="bg-red-600 t-head">
                    <th colSpan={4} className="text-left py-2">ចេញទី:<span>{product['round_id'] ? product['round_id'] : "---::---"}</span> &nbsp; ចេញថ្ងៃ:{productTime && productTime.format('DD-MM-YYYY')}&nbsp; ម៉ោង​:{productTime && productTime.format("h:mm A")} </th>
                </tr>
            </thead>
            <tbody data-round="1719216900" className="round-1719216900 bg-white">
                <tr className="border-b-[1px]">
                    <th className="py-2 px-2">
                        <div className="flex justify-center items-center rounded-sc bg-sc">
                            <span className="post-code-sc">A: </span>
                            <span className="ml-2 flex space-x-1 number-sc number-sc-1" id="jack-port" data-num="84543">
                                {
                                 product["A"] ? product["A"].split('').map((n:string) => (
                                    <span className="digit bg-white rounded">{n}</span>
                                 )) : "---::---"

                                }
                              
                            </span>
                        </div>
                    </th>
                    <th className="py-2 px-2">
                        <div className="flex justify-center items-center rounded-sc bg-sc">
                            <span className="post-code-sc">B: </span>
                            <span className="ml-2 flex space-x-1 number-sc number-sc-2" id="jack-port" data-num="84543">
                                {
                                    product["B"] ? product["B"].split('').map((n:string) => (
                                        <span className="digit bg-white rounded">{n}</span>
                                    )) : "---::---"
                                }
                                {/* <span className="digit bg-white rounded">5</span>
                                <span className="digit bg-white rounded">6</span>
                                <span className="digit bg-white rounded">4</span>
                                <span className="digit bg-white rounded">1</span>
                                <span className="digit bg-white rounded">6</span> */}
                            </span>
                        </div>
                    </th>
                </tr>
                <tr className="border-b-[1px]">
                    <th className="py-2 px-2">
                        <div className="flex justify-center items-center rounded-sc bg-sc">
                            <span className="post-code-sc">C: </span>
                            <span className="ml-2 flex space-x-1 number-sc number-sc-3" id="jack-port" data-num="84543">
                                {
                                    product["C"] ? product["C"].split('').map((n:string) => (
                                        <span className="digit bg-white rounded">{n}</span>
                                    )) : "---::---"
                                }
                                {/* <span className="digit bg-white rounded">6</span>
                                <span className="digit bg-white rounded">8</span>
                                <span className="digit bg-white rounded">3</span>
                                <span className="digit bg-white rounded">3</span>
                                <span className="digit bg-white rounded">5</span> */}
                            </span>
                        </div>
                    </th>
                    <th className="py-2 px-2">
                        <div className="flex justify-center items-center rounded-sc bg-sc">
                            <span className="post-code-sc">D: </span>
                            <span className="ml-2 flex space-x-1 number-sc number-sc-4" id="jack-port" data-num="84543">
                                 {
                                    product["D"] ? product["D"].split('').map((n:string) => (
                                        <span className="digit bg-white rounded">{n}</span>
                                    )) : "---::---"
                                }
                                {/* <span className="digit bg-white rounded">1</span>
                                <span className="digit bg-white rounded">5</span>
                                <span className="digit bg-white rounded">3</span>
                                <span className="digit bg-white rounded">0</span>
                                <span className="digit bg-white rounded">7</span> */}
                            </span>
                        </div>
                    </th>
                </tr>
            </tbody>
        </table>
    </div>
    
    
    )
}


export default TodayResult;