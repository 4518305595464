import React, { useState, useEffect } from 'react';
import NoTimeLoaded from './element/noTimeLoaded';

const CountdownTimer: React.FC<{ targetTimestamp: number,cls?:string,cb?: Function }> = ({ targetTimestamp,cls, cb }) => {
  // console.log(targetTimestamp,'productTime');
  // targetTimestamp = targetTimestamp || 0;
  targetTimestamp = Math.floor(targetTimestamp);
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    // console.log(targetTimestamp,'targetTimestamp');
    // if(!targetTimestamp) return 0;
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    const remainingTime = targetTimestamp - currentTime;
    return Math.max(remainingTime, 0);
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
      // console.log(timeRemaining,'timeremaining',targetTimestamp);
      if(timeRemaining <= 1 && cb) {
        cb(true);
      }
    }, 1000); // Update every 1 second
    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [timeRemaining,targetTimestamp]);
 
  const formatTime = (timeInSeconds: number) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds % 3600) / 60);
    const seconds = timeInSeconds % 60;

    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  return (
    <div>
      <p className={`text-black ${cls}`}>{targetTimestamp != undefined && timeRemaining ? formatTime(timeRemaining) : <NoTimeLoaded></NoTimeLoaded>}</p>
    </div>
  );
};

export default CountdownTimer;
