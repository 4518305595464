import { lottoChannel } from '../../util';
interface iBetBoxState {
  betAmount: number;
  selectedChannel: string[];
  selectedLottoType: number;
  totalBetTimes: number;
}

const initialState: iBetBoxState = {
  betAmount: 100,
  selectedChannel: lottoChannel,
  selectedLottoType: 0,
  totalBetTimes: 1,
};

const BetBoxState = (state: iBetBoxState = initialState, action: any) => {
  switch (action.type) {
    case 'ONSELECT':
      return {
        ...state,
        betAmount: action.data,
      };
    case 'ONSELECTCHANNEL':
      return {
        ...state,
        selectedChannel: action.data,
      };
    case 'ONSELECTLOTTOTYPE':
      return {
        ...state,
        selectedLottoType: action.data,
      };
    case 'ONSETBETTIMES':
      return {
        ...state,
        totalBetTimes: action.data,
      };
    case 'ONUPDATEBETTIMES':
      return {
        ...state,
        totalBetTimes: state.totalBetTimes + action.data,
      };
    default:
      return state;
  }
};

export default BetBoxState;
