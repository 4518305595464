import { UPDATE_LOGIN, LOGOUT } from '../actionType/login';
export const loginAction = (isAuthenticated: boolean) => ({
  type: UPDATE_LOGIN,
  isAuthenticated: isAuthenticated,
});

export const logOutAction = () => ({
  type: LOGOUT,
  isAuthenticated: false,
});
