import { iUserInfo } from '../../interface/iUser';
export const setUserInfo = (value: iUserInfo) => ({
  type: 'ONSETUSERINFO' as const,
  data: value,
});

export const updateBalance = (value: number) => ({
  type: 'ONUPDATEBALANCE' as const,
  data: value,
});
export const updateUsername = (value: string) => ({
  type: 'ONUPDATEUSERNAME' as const,
  data: value,
});
export const updatePhone = (value: string) => ({
  type: 'ONUPDATEPHONE' as const,
  data: value,
});

export const updateComm = (actionType: string, value: number) => ({
  type: 'ONUPDATECOMM' as const,
  data: value,
  actionType: actionType,
});
