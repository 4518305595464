import { useTranslation } from "react-i18next";
import Header from "./Header";
import BackButton from "./BackButton";
import { defaultAmountDeposit, formatCurrency, formatCurrencyDecimal, formatNumber, shortenString } from "../util";
import { selectedCurrency } from "../util";
import { useState } from "react";
import { mainColor } from "../color";
import ABAKHQR from "../assets/payment/KHQR.jpg"
import ButtonStyled from "./element/ButtonStyles";
import { toast } from 'react-toastify';
import MyToastContainer from "./MyToastContainer";
import MyAxios from "../http/MyAxios";
import { iResponse } from "../interface/IAPIResponse";
import { iUserInfo } from "../interface/iUser";
import { useSelector } from "react-redux";
import { BtnSeeTransaction } from "./element/BtnSeeTransaction";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";
import { ArrowDownward } from "@mui/icons-material";
import FileUploadButton from "./element/ButtonUpload";
interface iDeposit {

}

const Deposit = (props: iDeposit): JSX.Element => {
    const http = new MyAxios();
    const { t } = useTranslation();
    const [amount, setAmount] = useState(0);
    const [file, setFile] = useState<File | null>(null);
    const userState: iUserInfo = useSelector((state: any) => (state.userState));
    const onClickAmount = (amount: number) => {
        setAmount(amount)
    }

    const onBetResponse = (res: iResponse) => {
        if (!(res.statusCode == 200)) {
            toast(t("something_went_wrong"), { type: "success" }); return;
        }

        toast(t("submit_successfully"), { type: "success" })

    }

    const handleFileChange = (file: File | null) => {
        setFile(file);
        if (file) {
          console.log('File selected:', file.name);
        }
      };

    const downloadImage = () => {
        let imageSrc = document.getElementById('khqr_code');
        const link = document.createElement('a');
       
        if(imageSrc && imageSrc.getAttribute('src') && imageSrc?.hasAttribute('src')) {
            const src = imageSrc.getAttribute('src');
           if(src) {
            link.href = src;
           }

           link.download = 'k24h_QR.jpg'; // Set the desired download filename
           link.click();
        }


      };

    const onSumit = () => {
        if (!amount) {
            toast(t("enter_balance"), { type: "error" });

            return;
        }

        if(!file) {
            toast(t("please_pay_slip"), { type: "error" });
            return;
        }

        let data = {
            type: 1,
            payment_id: 1,
            file: file,
            amount: amount
        }

        http.pos({ path: 'deposits', data: data, cb: onBetResponse,headers: {
            'Content-Type': 'multipart/form-data',
        } });

    }

    return (
        <div>
            <Header title={t('deposit')} back={<BackButton></BackButton>}></Header>
            <div className="mx-4">
                <div className="py-4">1. {t("please_fill_payment_detail")}</div>
                {/* <div>  <BtnSeeTransaction></BtnSeeTransaction></div> */}
                <div className="grid grid-cols-3 gap-1">
                    {
                        defaultAmountDeposit.map((a: number) => (
                            <button onClick={() => { onClickAmount(a) }} className={`border-[1px] px-4 py-2 ${amount == a ? `bg-[${mainColor}] text-white` : ''}`}> {formatNumber(a)}</button>
                        ))
                    }
                </div>

                <div>

                    <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <span className="text-gray-500 sm:text-sm">KHR</span>
                        </div>
                        <input type="number" name="price" placeholder={t("please_enter_amount")} value={amount} onChange={(e) => { setAmount(+e.target.value) }} id="price" className="block w-full focus:outline-none rounded-md border-0 py-1.5 pl-14 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 sm:text-sm sm:leading-6" />
                    </div>
                    <div className="grid grid-cols-2 gap-1 mt-2">
                        <button onClick={onSumit} className={`bottom-0 text-white left-0 bg-[${mainColor}] py-2 rounded-sm w-full`}>{t("submit")}</button>
                       
                        <button  className={`bg-[${mainColor}]  rounded-sm text-white`}><Link to={"/dep-with-trx"}>{t("transaction")}</Link></button>
                    </div>
                </div>

                <div className="py-2">2. {t("payment_method")}</div>
                <div className="grid grid-cols-5 mb-2 items-center">
                    <FileUploadButton onFileChange={handleFileChange}></FileUploadButton>
                    {<p className="text-gray-500 col-span-3 w-full border-[1px] border-solid p-1">{file ? shortenString(file.name,12)  : "No File Selected"}</p>}
                </div>
                <div>
                    <img src={ABAKHQR} id="khqr_code" alt="" />
                    {/* <IconButton className="flex flex-col text-sm text-black">
                        <span>save</span>
                        <ArrowDownward></ArrowDownward>
                    </IconButton> */}
                   {/* <div className="underline py-2" onClick={downloadImage}>{t('save_qr_code')}</div> */}
                </div>
                
            </div>
            <MyToastContainer></MyToastContainer>
        </div>
    )
}

export default Deposit;