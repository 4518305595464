import React, { MouseEventHandler, useState,forwardRef, ForwardRefRenderFunction } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
interface ImyDatePicker {
  title?: string,
  onSelect: Function,
  onSearch: any,
  selectedDate: any
}

const MyDatePicker = ({title,onSelect,selectedDate,onSearch}:ImyDatePicker) => {
  // const [startDate, endDate] = selectedDate;
  const handleDateChange = (date:any) => {
    onSearch(date);
    onSelect(date);
  };

  interface iCustomInput {
    value?: string,
    onClick?: MouseEventHandler
  }
  const CustomInput = forwardRef(({ value, onClick }:iCustomInput, ref:any) => (
    <button className="border-[1px] w-56 solid p-1" onClick={onClick} ref={ref}>
      {value}
    </button>
  ));

  return (
    <div>
      {title && <h2>{title}</h2>}
      <DatePicker
      className='border-[1px] solid p-1'
        selectsRange={false}
        selected={selectedDate}
        // onSelect={onSearch}
        // startDate={startDate}
        // endDate={endDate}
        onChange={handleDateChange}
        customInput={<CustomInput></CustomInput>}
        withPortal
        dateFormat="dd/MM/yyyy" // Customize date format as needed
      />
    </div>
  );
};

export default MyDatePicker;
