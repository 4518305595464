
import { Button,styled } from "@mui/material";
import { mainColor } from "../../color";
const ButtonStyled = styled(Button)({
    width: '100%',
    backgroundColor: mainColor,
    color: "white",
    position: "static",
    '&:hover': {
      backgroundColor: mainColor, // Replace with your hover color code
    },
    '&:focus': {
      backgroundColor: mainColor, // Same as default background color
    },
    '&:active': {
      backgroundColor: mainColor, // Same as default background color
    },
  });

  export default  ButtonStyled;