import { MouseEventHandler } from "react"
import { LottoType, NLottoType, formatCurrency, stringBetStatus } from "../util"
import moment from "moment"
import { useDispatch } from "react-redux"
import { mainColor } from "../color"
import { useTranslation } from "react-i18next"
interface BetData {
    round_id: number;
    bet_amount: number;
    lucky_num: string;
    status: number;
    win_amount: number;
    product_time: number;
    lotto_channel: string;
    lotto_type: string;
    bet_time: string;
    count: number,
    is_reverse: boolean,
    bet_count: number,
    _sum: {
        bet_amount: number,
    }
}
interface BetSummary {
    bet_amount: number,
    win_amount: number
    round_id: number
}

interface iBetDetail {
    data: BetData[],
    summary: BetSummary
    onCloseDetail: MouseEventHandler
}


const BetDetail = (props:iBetDetail) => {
    let data = props.data;
    let summary = props.summary;
    console.log(summary,'summary');
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const date  = summary &&  moment(summary.round_id * 1000);
    return (
        <div className={`fixed flex flex-col w-full h-full z-20  top-[0] bg-[white]`}>
          
            <button onClick={props.onCloseDetail} className="flex m-2 rounded-full bg-red-500 w-10 h-10 text-white justify-center items-center">X</button>

            <div className="flex justify-between mt-2 flex-row py-2 px-12 border-[1px] border-red-500 text-red-500 border-solid h-[40px]">
                <div>ថ្ងៃខែ៖ {date.format("DD/MM/YYY")}</div> 
                <div>ម៉ោង: {date.format('h:mm A')}</div>
                </div>
            <div className="grid grid-cols-8 mt-2 flex-row py-2 border-[1px] bg-red-500 text-white border-solid h-[40px] px-4">
            <div  className="text-left">ប្រភេទ</div>
            <div  className="text-left">លេខ</div>
            <div  className="text-left col-span-2">ចាក់</div>
            <div  className="text-left col-span-2">ប៉ុស្តិ៍</div>
            
            <div  className="text-left col-span-2">សរុប</div>
            </div>
            <div className={`w-full overflow-x-scroll bg-white`}>

               {data && data.map((e:any) => ( <BetList data={e}></BetList>))}

            </div>

            <div className="bg-red-500 py-2 text-white">សរុបទឹកប្រាក់ : {formatCurrency(summary.win_amount > 0 ? summary.win_amount : summary.bet_amount )}</div>
            <div className="bg-red-600 py-2 text-white">
                បញ្ចាក់៖ ក្រុមហ៊ុនយើងខ្ញុំទូទាត់ផ្អែកតាមតារាងខាងលើ
                <div>(2 ខ្ទង់សង់  1x95) (3 ខ្ទង់សង់  1x800) (5 ខ្ទង់សង់  1x60 000)</div>
            </div>
        </div>
    )
}

export default BetDetail;

interface iBetList {
    data: BetData
}

const BetList = (props:iBetList) => {
    let statusColor = '';
    const data = props.data;
    let win = data.win_amount;
    let bet_amout = data.bet_amount;
    if(data.win_amount > 0) {
        statusColor = "text-[#50F242]";
    } else if(!data.win_amount) {
        statusColor = "text-[gray]";
        win = bet_amout;
    } else {
        win = bet_amout;
        statusColor = "text-[#FB0000]";
    }

    return (
        <div className="grid grid-cols-8 gap-2 justify-between flex-row py-2 border-[1px] border-solid h-[40px] px-4 text-black">
        {/* <div className="w-[55px] h-[55px] rounded-full bg-gradient-to-b from-[#FB0605] to-[#950403] text-white text-2xl font-bold pt-2">{LottoType[+data.lotto_type]}</div> */}
        <div className="text-left w-full">{LottoType[+data.lotto_type].replace('D','')}លេខ</div>
        <div className="text-left w-full">{data.lucky_num.replace(/,/g, '')}<span>{data.is_reverse ? "x" : ""}</span></div>
        <div className="text-left w-full col-span-2">{formatCurrency(data.bet_amount / data.bet_count)}</div>
        <div className="text-left w-full col-span-2">{data.lotto_channel}</div>
        
        <div className="text-lg text-left w-full col-span-2">
            <div className={`${statusColor} text-black`}>{formatCurrency(data.bet_amount)}</div>
            {/* <div className="text-white">{moment(+data.round_id * 1000).format("YYYY/MM/DD HH:mm")}</div> */}
        </div>
    </div>
    )
}