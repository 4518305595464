import React, { ChangeEvent, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
interface iProps {
    list: any[],
    setSelected:Function,
    selected: number
}
function Dropdown(props:iProps) {
//   const [selectedOption, setSelectedOption] = useState('');
  const list = props.list
  const setSelected = props.setSelected;
  const selected = props.selected;
  const handleChange = (event:any) => {
    setSelected(event.target.value);
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="dropdown-label">ជ្រើសរើស</InputLabel>
      <Select
        labelId="dropdown-label"
        id="dropdown"
        value={selected > 0 ? selected : ''}
        onChange={handleChange}
        label="ជ្រើសរើស"
      >
        {list.length > 0 ? list.map((e:any) => (   <MenuItem value={e.id}>{e.bank_name} : {e.bank_no} ({e.beneficiary_name})</MenuItem>)) : <MenuItem >No Payment added!</MenuItem>}
      </Select>
    </FormControl>
  );
}

export default Dropdown;
