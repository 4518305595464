// LoginPage.tsx
import React, { useState } from 'react';
import { styled } from '@mui/system';
import { TextField, Button, Container, Paper, Typography,InputAdornment, FormHelperText } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ButtonStyled from './element/ButtonStyles';
import BackButton from './BackButton';
import { toast } from 'react-toastify';
import MyToastContainer from './MyToastContainer';
import { passwordValidate, phoneValidate, smsCodeValidate } from '../validator/RegisterValidator';
import http from '../http/Request';
import {IconButton} from '@mui/material';
import { Visibility,VisibilityOff } from '@mui/icons-material';
import CountDownSecond from './CountDownSecond';
import Done from './element/Done';

const LoginPageContainer = styled(Container)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // height: '100vh',
});

const PaperStyled = styled(Paper)({
  padding: '20px',
  width: '300px',
  textAlign: 'center',
});

const TextFieldStyled = styled(TextField)({
  width: '100%',
  marginBottom: '15px',
});

const RegisterPage: React.FC = () => {
  const {t} = useTranslation();
  const navigate  = useNavigate();
  const dispatch  = useDispatch();
  const [isSent, setIsSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [ShowSuccessLogin, setShowSuccessLogin] = useState(false);
  interface iErrorsRegister {
    phone: boolean,
    username:boolean,
    password: boolean,
    code: boolean,
    phoneExist:boolean
  }

  const [smsCode, setSmsCode] = useState<string | null>('');
  const [password, setPassword] = useState<string | null>('');
  const [phone, setPhone] = useState<string | null>('');
  const [username, setUsername] = useState<string | null>('');
  const [errors, setErrors] = useState<iErrorsRegister>({phone: false, password:false, code: false,phoneExist: false, username:false});
  const handleRegisterResponse  = (e:any) => {
    if(e && e.statusCode == 200) {
      setShowSuccessLogin(true);
      let timer = setTimeout(() => {
        clearTimeout(timer);
        navigate("/login");
      }, 1500);
     
    } else if(e.statusCode == 700 ) {
        setErrors((errors:iErrorsRegister) => {return {...errors ,phoneExist: true}})
    } else {
      phoneValidate(false,setErrors);
    }
    console.log(e,'e');
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };


  const handleUserRegister = () => {
    phoneValidate(phone,setErrors);
    passwordValidate(password,setErrors);
 
    if(username == '' || username == null) { // check if user no
      setErrors((oldState:iErrorsRegister) => {
        return {
          ...oldState,
         username: true
        }
    })} else {
      setErrors((oldState:iErrorsRegister) => {
        return {
          ...oldState,
         username: false
        }
      })
    }

    // smsCodeValidate(smsCode,setErrors);

    if(!phone || !password || !username) return;

    console.log(phone,username,password);

    http.pos({path: "user/register",data: {phone: phone,password: password,username: username},cb: handleRegisterResponse });
  };

  const handlePhoneVerify = () => {
    phoneValidate(phone,setErrors);
    // passwordValidate(password,setErrors);
    // smsCodeValidate(smsCode,setErrors);
    http.pos({path: "user/send-phone-otp",data: {phone: phone},cb: handleRegisterResponse });
    
    if(!phone) return;
    setIsSent(true);
    console.log("handlePhoneVerify");
  }

  return (
    <>  
      <BackButton cls='text-black' sx={{display:"flex"}}></BackButton>
      <LoginPageContainer className='mt-52'>
        <PaperStyled elevation={3}>
          <Typography variant="h5" gutterBottom>
            {t('register')}
          </Typography>
          <form>
          <TextFieldStyled
              error={errors.username || errors.phoneExist}
              label={t('username')}
              variant="outlined"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className='mb-0'
              helperText={errors.username ? t("username_required") : errors.phoneExist ? t("usesr_already_exist") : ""}
            />

          <TextFieldStyled
              error={errors.phone || errors.phoneExist}
              label={t('phone')}
              variant="outlined"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              className='mb-0'
              helperText={errors.phone ? t("phone_required") : errors.phoneExist ? t("phone_already_exist") : ""}
            />

            <TextFieldStyled
              error={errors.password}
              label={t('password')}
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              helperText={errors.password ? t("password_required") : "" }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <Visibility />  :<VisibilityOff />}
                  </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {/* <TextFieldStyled
              error={errors.code}
              label={t('otp_code')}
              variant="outlined"
              value={smsCode}
              helperText={errors.code ? t("code_required") : '' }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                   {isSent ? <CountDownSecond cb={setIsSent} seconds={5}></CountDownSecond> :   <ButtonStyled variant='contained' onClick={handlePhoneVerify}>{t('send_code')}</ButtonStyled> }
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSmsCode(e.target.value)}
            /> */}

            <ButtonStyled variant="contained" onClick={handleUserRegister}>
              {t("register")}
            </ButtonStyled>
          </form>
        </PaperStyled>
      </LoginPageContainer>
      <MyToastContainer></MyToastContainer>
      {ShowSuccessLogin && <Done text={t("register_success")}></Done>}
    </>

  );
};

export default RegisterPage;
