import { loadingTimeout, lottoChannel, mainPx } from "../util"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MyDatePicker from "./element/DatePick";
import { mainColor } from "../color";
import moment from "moment";
import MyAxios from "../http/MyAxios";
import NoRecordRound from "./element/NoRecordRound";
import HttpLoading from "./element/HttpLoading";
interface iResults {
    setBackDropOpen: Function
}
export default function Result({ setBackDropOpen }: iResults) {
    const { t } = useTranslation();
    const http: MyAxios = new MyAxios();
    const [searchQuery, setSearchQuery] = useState({
        startDate: moment().startOf('day').format("X"),
        endDate: moment().endOf("day").format("X")
    });
    const [page, setPage] = useState(1);
    const [listData, setListData] = useState<any>([]);
    // const [backDropOpen, setBackDropOpen] = useState(false);
    const [dateRange, setDateRange] = useState(moment().startOf('day').format());
    const onSearch = (startDate:any) => {
        // const startDate = dateRange;
        setPage(1);
        http.get({ path: "/product", query: { state: 2, limit: 7, startDate: moment(startDate).startOf("day").format("X"), endDate: moment(startDate).endOf("day").format("X"), }, cb: OnResponse });
        // console.log(dateRange, 'date');
        // setSearchQuery({ startDate: moment(startDate).startOf('day').format("X"), endDate: moment(startDate).endOf('day').format("X") });
        // console.log(searchQuery, 'searchQuery');
    }

    const OnResponse = (res: any) => {
        setBackDropOpen(true);
        let timer = setTimeout(() => {
            setBackDropOpen(false);
            clearTimeout(timer);
            if (page == 1) {
                setListData(res.data);

            } else {
                setListData((prevData: any) => [...prevData, ...res.data]);
            }
        }, loadingTimeout)


    }

    useEffect(() => {
        console.log(searchQuery, 'searchQuery');
        setPage(1);
        http.get({ path: "/product", query: { state: 2, limit: 7, startDate: searchQuery.startDate, endDate: searchQuery.endDate, }, cb: OnResponse });

    }, [])

    // useEffect(() => {
    //     http.get({ path: "/product", query: { state: 2, limit: 7, startDate: searchQuery.startDate, endDate: searchQuery.endDate }, cb: OnResponse });

    //     const handleScroll = () => {
    //         if (
    //             window.innerHeight + document.documentElement.scrollTop ===
    //             document.documentElement.offsetHeight
    //         ) {
    //             setPage(prevPage => prevPage + 1); // Increment page number
    //         }
    //     };

    //     window.addEventListener('scroll', handleScroll);

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll);
    //     };
    // }, [page])

    interface iResultItem {
        round: number,
        date: number
    }

    const ResultList = ({ data,index }: any) => {
        let date = moment(data.product_time * 1000);
        return (
            <div className={`px-[${mainPx}] ${index == 0 ? " mt-[64px]" : " mt-[25px]"} border-t-2 border-gray-100 pb-8`}>

                <div className={`flex flex-row justify-between my-2 `}>
                    <div className="flex flex-row text-lg">
                        <div className="px-1">{t('round')}</div>
                        <div className="">{data.round_id}</div>
                    </div>
                    <div className="text-lg">{date.format("DD/MM/YYYY")} <span>{date.format("h:mm A")}</span></div>
                </div>
                <div className={`flex flex-col mt-8 items-center `}>
                    {
                        lottoChannel && lottoChannel.map((l: any) => (
                            <div className="flex my-1">
                                <LottoNumber data={data} channel={l}></LottoNumber>
                            </div>
                        ))
                    }
                </div>
            </div>
        )

    }


    const LottoNumber = ({ data, channel }: any) => {
        let result = data[channel] && data[channel].toString().split("");
        return (
            <>
                <div key={`channel-${channel}`} className={`p-3 w-[40px] h-[40px] rounded-full text-center`}>{channel} </div>
                {result && result.map((e: any, index: number) => (<div className={`shadow-lg p-3 ${index < 2 ? 'bg-[green] shadow-[#50c265]' : `bg-[${mainColor}] shadow-[#0d4173]`} text-white w-[40px] h-[40px] mx-1 rounded-full text-center`}>{e}</div>))}
            </>
        )
    }

    return (
        <>
            <div className="w-full overflow-y-scroll" style={{ height: "calc(100vh - 124px)" }}>
                <div className="flex fixed w-full z-10 bg-white justify-center p-2 pb-6 gap-2">
                    <MyDatePicker onSearch={onSearch} selectedDate={dateRange} onSelect={setDateRange}></MyDatePicker>
                    {/* <button onClick={onSearch} className={`flex bg-[${mainColor}] px-2 py-1 text-white`}>Search</button> */}
                </div>
                {listData.length ? listData.map((item: any,index:number) => (
                    <ResultList index={index} data={item}></ResultList>
                )) : <NoRecordRound></NoRecordRound>}
            </div>
            {/* <HttpLoading backDropOpen={backDropOpen}></HttpLoading> */}
        </>
    )
} 