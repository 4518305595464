import { iUserInfo } from '../../interface/iUser';

const initialState: iUserInfo = {
  username: '',
  phone: 0,
  balance: 0,
  comm: 0,
  id: 0
};

const UserInfo = (state: iUserInfo = initialState, action: any) => {
  switch (action.type) {
    case 'ONSETUSERINFO':
      return {
        ...state,
        ...action.data,
      };
      break;
    case 'ONUPDATEBALANCE':
      return {
        ...state,
        balance: state.balance + action.data,
      };

      break;
    case 'ONUPDATEUSERNAME':
      return {
        ...state,
        username: action.data,
      };

      break;
    case 'ONUPDATEPHONE':
      return {
        ...state,
        phone: action.data,
      };

      break;
    case 'ONUPDATECOMM':
      if (action.actionType == '-') {
        return {
          ...state,
          comm: state.comm - action.data,
        };
      } else {
        return {
          ...state,
          comm: state.comm + action.data,
        };
      }

    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default UserInfo;
