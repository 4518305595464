import { useTranslation } from "react-i18next";
import Header from "./Header";
import BackButton from "./BackButton";
import { defaultAmountDeposit, formatCurrency, formatCurrencyDecimal, shortenString } from "../util";
import { selectedCurrency } from "../util";
import { ChangeEvent, useEffect, useState } from "react";
import { mainColor } from "../color";
import ABAKHQR  from "../assets/payment/KHQR.jpg"
import ButtonStyled from "./element/ButtonStyles";
import { toast } from 'react-toastify';
import MyToastContainer from "./MyToastContainer";
import MyAxios from "../http/MyAxios";
import { iResponse } from "../interface/IAPIResponse";
import { iUserInfo } from "../interface/iUser";
import { useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { BtnSeeTransaction } from "./element/BtnSeeTransaction";
import { formatNumber } from "../util";
import { DeleteOutline } from "@mui/icons-material";
import {IconButton} from "@mui/material";
interface iWithdrawal {

}

const Payment = (props:iWithdrawal):JSX.Element => {
    const http = new MyAxios();
    const {t} = useTranslation();
    const userState:iUserInfo = useSelector((state:any) => (state.userState));
    const [bankName,setBankName] = useState("");
    const [bankNo,setBankNo] = useState('');
    const [BankOwer,setBankOwer] = useState("");
    const [BankList,setBankList] = useState<any[]>([]);
    const [data,setData] = useState({});

    const onAddResponse = (res:any) => {
        if((res.status == 1))  {
            let data = {
                bank_name: bankName,
                bank_no: bankNo,
                beneficiary_name: BankOwer
            }

            addBank(data);
            toast(t("add_successfully"), {type: "success"})
           
        } else {
            toast(t("something_went_wrong"), {type: "success"}); return;
        }

    }

    const addBank = (newBank:any) => {
        setBankList((prevBankList:any) => {
            let prevlength  = prevBankList.length;
            prevlength > 0 ?  newBank.id = prevBankList[prevlength - 1].id + 1 : newBank.id =  1;
            return [...prevBankList, newBank];
        });
      };

      const removeBank = (id:number) => {
        setBankList((prevBankList) => prevBankList.filter(bank => bank.id !== id));
      };

    const onListesponse = (res:any) => {
        if(res.status == 1) {
            let data = res.data;
            setBankList(data);
        }
    }

    const onSumit = () => {
        if(bankName == "" || bankNo == '' || BankOwer == "") {
            toast(t("please_fill_all_input"), {type: "error"});

            return;
        }

        let exist = BankList.filter((e:any) => e.bank_no == bankNo );

        if(exist && exist.length > 0){  toast(t("already_exist"), {type: "error"});;return;}

        let data = {
            bank_name: bankName,
            bank_no: bankNo,
            beneficiary_name: BankOwer
        }


        http.pos({path: 'payment-detail', data: data, cb: onAddResponse });

    }

    const onDelete = (id:number) => {
        if(!id) {alert("no ID"); return;}
        
          http.delete({path: 'payment-detail/' + id , cb: (e:any) => {
            removeBank(id);
            toast(t("delete_successfully"), {type: "success"});
            
          } });
    }

    useEffect(() => {
        http.get({path: 'payment-detail', cb: onListesponse });
    }, [])
    return (
        <div>
        <Header title={t('payemnt_detail')} back={<BackButton></BackButton>}></Header>
        <div className="mx-4">
            
            <div className="py-4">1. {t("add_new_payment_detail")}</div>

            <div>
                {/* <div className="mb-5">{t("bank_detail")}</div> */}
                
                <div className="flex flex-col gap-4">
                    <TextField
                     size="small"
                    required
                    id="outlined-required"
                    label={t("bank_name")}
                    onChange={(e:ChangeEvent<HTMLInputElement>) => {
                        setBankName(e.target.value)
                    }}
                    defaultValue=""
                    />
                    <TextField
                    onChange={(e:ChangeEvent<HTMLInputElement>) => {
                        setBankOwer(e.target.value)
                    }}
                    size="small"
                    required
                    id="outlined-required"
                    label={t("beneficiary_name")}
                    defaultValue=""
                    />
                    <TextField
                    onChange={(e:ChangeEvent<HTMLInputElement>) => {
                        setBankNo(e.target.value)
                    }}
                     size="small"
                    type="number"
                    required
                    id="outlined-number"
                    label={t("bank_no")}
                    defaultValue=""
                    />
                </div>  
            </div>

            <button onClick={onSumit} className={ `mt-3 text-white bottom-0 left-0 bg-[${mainColor}] py-3 w-full rounded-sm`}>{t("add_bank")}</button>

            <div className="py-4">2. {t("list_bank_detail")}</div>

            <div className="flex  flex-col">
                <div className="grid grid-cols-8 border-[1px] py-2">
                    <div>{t("no")}.</div>
                    <div className="col-span-2">{t("bank_name")}</div>
                    <div className="col-span-3">{t("beneficiary_name")}</div>
                    <div className="col-span-2">{t("bank_no")}</div>
                </div>

                <div className="mt-2">
                    {BankList.length > 0 ? BankList.map((e:any) => (
                                            <div className="grid grid-cols-8 border-[1px] py-2">
                                            <div>{e.id}.</div>
                                            <div className="col-span-2">{shortenString(e.bank_name, 6)}</div>
                                            <div className="col-span-2">{shortenString(e.beneficiary_name, 10)}</div>
                                            <div className="col-span-2">{shortenString(e.bank_no, 8)}</div>
                                            <IconButton onClick={onDelete.bind(this,e.id)} ><DeleteOutline className="text-red-500"></DeleteOutline></IconButton>
                                        </div>
                    )) : <div className="flex justify-center items-center">no bank list</div> }

                </div>
            </div>

        </div>
        <MyToastContainer></MyToastContainer>
    </div>
    )
}

export default Payment;