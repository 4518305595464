import * as React from 'react';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import { HomeOutlined,PersonOutline,ReceiptOutlined } from '@mui/icons-material';
import {HomePage,HomeHeader} from './Home';
import Me from './Me';
import Result from './Result';
import { useState } from 'react';
import { mainColor } from '../color';
import Header from './Header';
import { useTranslation } from 'react-i18next';
import logo from "../assets/k24h.png"
import LoadingSpinner from './Loading';
import { useSelector } from 'react-redux';
import { setUserInfo } from "../state/action/user";
import { iUserInfo } from "../interface/iUser";
import { loginAction,logOutAction } from "../state/action/login";
import { useDispatch } from 'react-redux';
import MyAxios from '../http/MyAxios';
import { setIsLoading } from '../state/action/app';
import { loadingTimeout } from '../util';
import HttpLoading from './element/HttpLoading';

export default function FixedBottomNavigation() {
  const {t} = useTranslation();
  const app = useSelector((state:any) => state.app);
  const login = useSelector((state:any) => state.login);
  const userState = useSelector((state:any) => state.userState);
  const selectedTab = localStorage.getItem("selectedTab") || 0;
  const [value, setValue] = React.useState(+selectedTab);
  const ref = React.useRef<HTMLDivElement>(null);
  const [selectedPage,setSelectedPage] = useState<JSX.Element>();
  const [title,setTitle] = useState<string | null>('');
  const [cls,setCls] = useState<string>('');
  const [header,setHeader] = useState<JSX.Element | null>(null);
  const [backDropOpen, setBackDropOpen] = useState(true);
  const dispatch = useDispatch();
  
  const http:MyAxios = new MyAxios();
  React.useEffect(() => {
    (ref.current as HTMLDivElement).ownerDocument.body.scrollTop = 0;
    if(value == 0  ) {
      setCls("fixed top-0 w-full z-10");
      setTitle('');
      setHeader(<HomeHeader logo={logo} balance={userState.balance}></HomeHeader>);
    
      setSelectedPage(<HomePage setBackDropOpen={setBackDropOpen}></HomePage>);
      localStorage.setItem("selectedTab",'0');
    } else if(value == 1) {
      setCls('');
      // cls = "fixed top-0 w-full z-10 bg-white";
      setTitle(t('result'));
      setSelectedPage(<Result setBackDropOpen={setBackDropOpen}></Result>);
      setHeader(null);
      localStorage.setItem("selectedTab",'1');
    } else {
      setCls('');
      setTitle(null);
      setHeader(null);
      setSelectedPage(<Me setBackDropOpen={setBackDropOpen} ></Me>);
     
      localStorage.setItem("selectedTab",'2');
    }

  }, [value,userState.balance]);

  // React.useEffect(() => {
  //   // dispatch(setIsLoading(true));
  //   const OnError = () => {

  //   }
    
  //   const onResponse = (res:any) => {
  //    const info:iUserInfo = res.data;
  //    setBackDropOpen(false);
  //    setTimeout(() => {
  //      dispatch(setIsLoading(false));
  //    },loadingTimeout)
  //     if(info.phone) {
  //       dispatch(setUserInfo(info));
  //       dispatch(loginAction(true));
  //     } else {
  //       dispatch(logOutAction());
  //     }

  //   }

  //   localStorage.getItem("token") && http.pos({path: '/user/verify',data: {},cb: onResponse, OnError: OnError}); 
  // },[])

  return (
    <>
       
       {(title || header) &&  <Header cls={cls} title={title} children={header}></Header>} 
        {/* {app.isLoading && <LoadingSpinner></LoadingSpinner>} */}
        <HttpLoading backDropOpen={backDropOpen}></HttpLoading>
        <Box sx={{ pb: 7 }} ref={ref}>
        <CssBaseline />
        {selectedPage && selectedPage}
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation
            showLabels
            value={value}
            sx={{
              background: mainColor,
              '& .MuiBottomNavigationAction-root': {
                color: 'white',
              },
              '& .Mui-selected, & .Mui-selected .MuiBottomNavigationAction-label': {
                color: 'black !important',
              },
            }}

            onChange={(event, newValue) => {
              setValue(newValue);
            }}
          >
            <BottomNavigationAction  label={t('home')} icon={<HomeOutlined />} />
            <BottomNavigationAction  label={t('result')} icon={<ReceiptOutlined />} />
            <BottomNavigationAction  label={t('me')} icon={<PersonOutline />} />
          </BottomNavigation>
        </Paper>
      </Box>

      <footer></footer>
    </>

  );
}