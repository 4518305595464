// LoginPage.tsx
import React, { useState } from 'react';
import { styled } from '@mui/system';
import { TextField, Container, Paper, Typography,Grid } from '@mui/material';
import { useDispatch } from 'react-redux';
import { loginAction } from '../state/action/login';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {setCookie} from "../util";
import http from '../http/Request';
import { Link } from 'react-router-dom';
import ButtonStyled from './element/ButtonStyles';
import LoadingSpinner from './Loading';
import { passwordValidate, phoneValidate } from '../validator/RegisterValidator';
import {InputAdornment} from '@mui/material';
import {IconButton} from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Done from './element/Done';
import { useSelector } from 'react-redux';
import BackButton from './BackButton';
import { setIsLoading } from '../state/action/app';
import { setUserInfo } from '../state/action/user';

interface ErrorTemplate {
  phone:  boolean ,
  block: boolean,
  password:  boolean,
  invalidPhone: boolean,
  invalidPassword:boolean
}

const LoginPageContainer = styled(Container)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // height: '100vh',
});

const PaperStyled = styled(Paper)({
  padding: '20px',
  width: '300px',
  textAlign: 'center',
});

const TextFieldStyled = styled(TextField)({
  width: '100%',
  marginBottom: '15px',
});


const LinkStyled = styled(Link)({
  textDecoration: "none",
  display : "flex",
  justifyContent: "end",
  cursor: 'pointer',
  color: 'white'
});

const LoginPage: React.FC = () => {
  const navigate  = useNavigate();
  const dispatch  = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [ShowSuccessLogin, setShowSuccessLogin] = useState(false);
  const loginState = useSelector((state:any) => state.login);
  // const loginState = state.login;
  const {isAuthenticated} = loginState;
  const [errors,setError] = useState<ErrorTemplate>({
    phone : false,
    block: false,
    password: false,
    invalidPhone:false,
    invalidPassword: false
  });
  const [isLoading,setLoading] = useState<boolean>(false);
  document.onkeyup = (e:KeyboardEvent) => {  // keyboard
    if(e.keyCode == 13 ) {
      handleLogin();
    }
  }
  
  const{t,i18n} = useTranslation();
  

  const handleLoginResult = (res:any) => {
            setLoading(false);
            dispatch(setIsLoading(false));
            if(res.status == 1 || res.statusCode == 200) {
              setShowSuccessLogin(true);
              dispatch(setUserInfo(res.info))
              let timer = setTimeout(() => {
                clearTimeout(timer);
                // setCookie('token',res.token,2);
                localStorage.setItem("token",res.token);
                dispatch(loginAction(true));
                navigate('/');
              }, 1500);
             } else if(res.status == 701){
              setError((e:ErrorTemplate) => {
                return {
                  ...e,
                  invalidPassword: true
                };
              });
             } else if (res.status == 702) {
              setError((e:ErrorTemplate) => {
                return {
                  ...e,
                  invalidPhone: true
                };
              });
             } else if(res.status == 0) {
              
              setError((e:ErrorTemplate) => {
                return {
                  ...e,
                  block: true
                };
              });
             } else {
              setError((e:ErrorTemplate) => {
                return {
                  block: false,
                  invalidPassword: false,
                  invalidPhone: false,
                  phone: false,
                  password: false
                };
              });
  
              dispatch(loginAction(false));
             }

  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleLogin = async() => {
     phoneValidate(username,setError);
     passwordValidate(password,setError);
     setError((e:ErrorTemplate) => {
      return {
          ...e,
          invalidPassword: false,
          invalidPhone: false,
      };
    });

     if(!username || !password) return;
      setIsLoading(true);
      await http.pos({path: '/user/login',data: {phone : username, password : password},cb : handleLoginResult});

  };

  return (
    <>
    <BackButton cls='text-black' sx={{display:"flex"}}></BackButton>
    <LoginPageContainer className='mt-52'>
     
      <PaperStyled elevation={3}>
        <Typography variant="h5" gutterBottom>
          {t('login')}
        </Typography>
        <form>
          <TextFieldStyled
            label={t('phone')}
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            error={errors.phone || errors.invalidPhone || errors.block}  // Set to true to display the input in an error state
            helperText={errors.phone ? t("phone_required") : errors.invalidPhone ? t("phone_not_register") :  errors.block ? t("user_blocked") : ""}
          />
          <TextFieldStyled
            label={t('password')}
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={errors.password || errors.invalidPassword}  // Set to true to display the input in an error state
            helperText={errors.password ?  t("password_required") : errors.invalidPassword ? t("invalid_password") : ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility />  :<VisibilityOff /> }
                </IconButton>
                </InputAdornment>
              ),
            }}
          />

        </form>
          <LinkStyled className='mb-2' sx={{color: 'black'}} to={'/forgot-pass'}>{t("forgot_password")}</LinkStyled>
          <Grid container rowSpacing={1} rowGap={1}>
            <Grid item xs={12}> 
              <ButtonStyled variant="contained" onClick={handleLogin}>
                {t('login')}
              </ButtonStyled>
            </Grid>

            <Grid item xs={12}>
                <LinkStyled to={'/register'}>
                    <ButtonStyled variant="contained">{t('register')}</ButtonStyled>
                  </LinkStyled>
            </Grid>
          </Grid>
      </PaperStyled>
    </LoginPageContainer>
    { ShowSuccessLogin && <Done text={t("login_success")}></Done>}
    </>

  );
};

export default LoginPage;
