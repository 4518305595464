import { Link } from "react-router-dom";
import { formatCurrencyDecimal } from "../../util";
interface iBalance {
    balance: number,
    currencySign? : string
}
const Balance = (props:iBalance) => {
    var currencySign = props.currencySign || "KHR";
    return (    <div className='balance'>
    <Link to={"/"}><div className='value'>{formatCurrencyDecimal(props.balance)}<span> {currencySign}</span></div></Link>
  </div>)
}

export default Balance;