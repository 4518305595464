import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../Header";
import BackButton from "../BackButton";
import { FormControl, InputLabel, FilledInput, InputAdornment, IconButton, Button, Input } from "@mui/material";
import { Visibility,VisibilityOff } from "@mui/icons-material";
import { ChangeEvent, useState } from "react";
import  locker from "../../assets/locker.jpg";
import { mainColor } from "../../color";
import { toast } from "react-toastify";
import MyToastContainer from "../MyToastContainer";
import { validatePassword } from "../../util";
import MyAxios from "../../http/MyAxios";
import { useSelector } from "react-redux";
import { iUserInfo } from "../../interface/iUser";
const ChangePass = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordCf, setShowPasswordCf] = useState(false);


    const [passwordCf, setPasswordCf] = useState<string>();
    const [password, setPassword] = useState<string>();

    const handleClickShowPasswordCf = () => setShowPasswordCf((show) => !show);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };
    const {t} = useTranslation();
    const userState:iUserInfo = useSelector((state:any) => (state.userState));
    const http:MyAxios = new MyAxios()
    const onSubmit  = () => {
        if(password == void 0 || password == "") {toast("enter your password"); return;}

        // let valide = validatePassword(password);
        // if(!valide) {
        //     toast("not valide");return;
        // }

        if(password !== passwordCf) { toast(t('pass_not_match'));return};

        http.patch({path: "/user/" + userState.id ,data: {password: password},cb: (e:any) => {
            if(e.status == 1) {
                toast(e.message,{onClose : () => {
                    navigate('/login');
                }});
                
            } else {
                toast(e.message);
            }
        }});




    }

    return (
        <>
        <Header title={t('change_password')} back={<BackButton></BackButton>}></Header>
        <div className="flex flex-col">
            <div className="flex w-32 items-center justify-center mx-auto my-11">
                <img src={locker} alt="" />
            </div>
            <FormControl sx={{ m: 1,}} variant="filled">
            <InputLabel htmlFor="filled-adornment-password">{t("new_password")}</InputLabel>
            <Input
            onChange={(e:React.ChangeEvent<HTMLInputElement>) => {setPassword(e.target.value)}}
                id="filled-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    >
                    {showPassword ? <Visibility /> : <VisibilityOff /> }
                    </IconButton>
                </InputAdornment>
                }
            />
            </FormControl>

            <FormControl sx={{ m: 1,}} variant="filled">
            <InputLabel htmlFor="filled-adornment-password">{t("confirm_password")}</InputLabel>
            <Input
                onChange={(e:ChangeEvent<HTMLInputElement>) => {setPasswordCf(e.target.value)}}
                id="filled-adornment-password"
                type={showPasswordCf ? 'text' : 'password'}
                endAdornment={
                <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPasswordCf}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    >
                    {showPasswordCf ? <Visibility /> : <VisibilityOff /> }
                    </IconButton>
                </InputAdornment>
                }
            />
            </FormControl>

            <div onClick={onSubmit} className={`px-4 w-32 mt-6 rounded-lg mx-auto bg-[${mainColor}] py-2 text-white border-[1px] border-solid shadow-sm shadow-[${mainColor}]`}>Submit</div>
        </div>
        <MyToastContainer></MyToastContainer>
        </>

    )
}

export default ChangePass;