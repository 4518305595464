import Header from "../Header";
import BackButton from "../BackButton";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ListItem, ListItemIcon } from "@mui/material";
import { ChevronLeft, ChevronRight, LanguageOutlined, LockClockOutlined, LogoutOutlined,PaymentOutlined, ResetTv, SecurityOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import LoadingSpinner from "../Loading";
interface iSettings {

}
const Settings = ({}:iSettings) : JSX.Element => {
    const app = useSelector((state:any) => state.app);
    const navigate = useNavigate();

    const logout = () => {
        console.log("loout");
        localStorage.removeItem("token");
        localStorage.removeItem("selectedTab");
        navigate('/login');
    }

    const {t} = useTranslation();

    return (
        <>
        <Header title={t('setting')} back={<BackButton></BackButton>}></Header>
        <div className="flex flex-col">
        <Link to={"/language"}>
        <div className='flex mx-4 justify-between px-4 py-3 mt-2 shadow-sm rounded-lg border'>
          <div className='items-center flex'>
            <ListItemIcon>
                <LanguageOutlined />
              </ListItemIcon> 
            {t('language')}
          </div>
          <ListItemIcon className='justify-end items-center'> <ChevronRight /></ListItemIcon>
        </div>
      </Link>
      <Link to={"/payment"}>
        <div className='flex mx-4 justify-between px-4 py-3 mt-2 shadow-sm rounded-lg border'>
          <div className='items-center flex'>
            <ListItemIcon>
                <PaymentOutlined />
              </ListItemIcon> 
            {t('payemnt_detail')}
          </div>
          <ListItemIcon className='justify-end items-center'> <ChevronRight /></ListItemIcon>
        </div>
      </Link>
      <Link to={"/change-pass"}>
        <div className='flex mx-4 justify-between px-4 py-3 mt-2 shadow-sm rounded-lg border'>
          <div className='items-center flex'>
            <ListItemIcon>
                <LockClockOutlined />
              </ListItemIcon> 
            {t('change_password')}
          </div>
          <ListItemIcon className='justify-end items-center'> <ChevronRight /></ListItemIcon>
        </div>
      </Link>
      {/* <Link to={"/language"}>
        <div className='flex mx-4 justify-between px-4 py-3 mt-2 shadow-sm rounded-lg border'>
          <div className='items-center flex'>
            <ListItemIcon>
                <LanguageOutlined />
              </ListItemIcon> 
            {t('language')}
          </div>
          <ListItemIcon className='justify-end items-center'> <ChevronRight /></ListItemIcon>
        </div>
      </Link> */}
            {/* <button className="py-4 pointer border solid"><Link to={"/language"}>{t("language")}</Link></button>
            <button className="py-4 border pointer solid"><Link to={"/change-pass"}>{t("change_password")}</Link></button> */}
            <button onClick={logout} className="flex mx-4 px-4 py-3 mt-2 shadow-sm rounded-lg border">
                <ListItemIcon>
                    <LogoutOutlined />
                </ListItemIcon> {t("logout")}
            </button>
        </div>
        {app.isLoading && <LoadingSpinner></LoadingSpinner>}
        </>

    )
}

export default Settings;