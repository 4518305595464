import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IconButton, SxProps } from '@mui/material';
import { ArrowBackIos } from '@mui/icons-material';

interface BackButtonType {
  url?: string; // Change String to string
  sx?: SxProps | null;
  cls?: string;
}

const BackButton = ({ url, sx, cls }: BackButtonType) => {
  const navigate = useNavigate();
  cls = cls || 'text-white';

  const goBack = () => {
    try {
      if (url) {
        navigate(url); // Use the provided url prop
      } else {
        if (window.history.length > 1) {
          navigate(-1); // Navigate to the previous page
        } else {
          navigate('/'); // Fallback route if history is empty
        }
      }
    } catch (error) {
      console.error('Navigation error:', error);
      navigate('/'); // Fallback route on error
    }
  };

  return (
    <IconButton sx={sx} onClick={goBack}>
      <ArrowBackIos className={cls} />
    </IconButton>
  );
};

export default BackButton;
