// Storing a cookie with an expiration date
export const setCookie = (
  name: string,
  value: string,
  daysUntilExpiration: number,
) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + daysUntilExpiration);

  const cookieValue = `${encodeURIComponent(name)}=${encodeURIComponent(value)}; expires=${expirationDate.toUTCString()}; path=/`;
  document.cookie = cookieValue;
};

export const LottoType: string[] = ['2D', '3D', '5D'];
export const NLottoType: Record<string, number> = {
  "2D": 0,
  "3D": 1,
  "5D": 2,
}; // 1: complete,2:rejected,3: pending

export const lottoTypeLuckyNumLength: number[] = [2, 3, 5];

export const lottoChannel: string[] = ['A', 'B', 'C', 'D'];

export const betAmountList: number[] = [
  100,200, 300, 500, 1000, 2000, 3000, 5000, 10000, 20000,
];
export const winningMultiplication: number[] = [95, 800, 60000]; // winning multiplication for 2D,3D,5D
export const betStatus: Record<string, number> = {
  pending: 1,
  win: 2,
  lose: 3,
  all: 10,
}; // 1: pending,2:win,3: lose , 4: all

export const transactionStatus: Record<number, string> = {
  1: 'pending',
  2: 'complete',
  3: 'rejected',
}; // 1: complete,2:rejected,3: pending
export const ntransactionStatus: Record<string, number> = {
  pending: 1,
  complete: 2,
  rejected: 3,
}; // 1: complete,2:rejected,3: pending

export const txType: Record<number, string> = {
  1: 'deposit',
  2: 'withdrawal',
  3: 'transfter',
}; // 1: deposit,2:withdrawal,3: transfer

export const ntxType: Record<string, number> = {
  deposit: 1,
  withdrawal: 2,
  transfer: 3,
}; // 1: deposit,2:withdrawal,3: transfer

export const bonusTxType: Record<number, string> = {
  1: 'in',
  2: 'out',
  10: 'all',
}; // 1: deposit,2:withdrawal,3: transfer

export const nBonusTxType: Record<string, number> = {
  in: 1,
  out: 2,
  all: 10,
}; // 1: deposit,2:withdrawal,3: transfer
export const minimumBonusToTransfer = 100; // KHR
export const defaultAmountDeposit: number[] = [
  10000, 20000, 50000, 100000, 200000, 400000,
];
export const dateFormate = 'YYYY/MM/DD HH:mm';
export const stringBetStatus: string[] = ['pending', 'win', 'lose']; // 1: pending,2:win,3: lose , 4: all
export const walletTab: string[] = ['deposit', 'transfer', 'withdrawal']; // 1: pending,2:win,3: lose , 4: all
export const bonusTab: string[] = ['in', 'out', 'all']; // 1: pending,2:win,3: lose , 4: all
export const currencyList: string[] = ['KHR', 'USD'];
export const selectedCurrency = currencyList[0];
export const UserComm = 10; // commission pay to user 10%

/// style

export const mainPx = '10px';

// function

export const formatCurrency = (
  amount: number,
  currencyCode: string = 'KHR',
): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
  });

  return formatter.format(amount);
};

export const formatAmountAsK = (amount: number): string => {
  if (amount >= 1000) {
    return (amount / 1000).toFixed(1) + 'k';
  } else {
    return amount.toString();
  }
};

export const formatCurrencyDecimal = (
  amount: number,
  currencyCode: string = 'KHR',
): string => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
  });

  return formatter.format(amount);
};

export function formatNumber(number: number, decimal?: number): string {
  decimal = decimal || 0;
  const suffixes = [
    { value: 1e2, symbol: ' រយរៀល' },
    { value: 1e3, symbol: ' ពាន់រៀល' },
    { value: 1e4, symbol: ' ម៉ឺនរៀល' },
    { value: 1e6, symbol: 'លានរៀល' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];

  const absNumber = Math.abs(number); // Handle negative numbers

  const lookup = suffixes
    .slice()
    .reverse()
    .find((item) => absNumber >= item.value);
  console.log(absNumber, 'absNumber', lookup);
  if (lookup) {
    if (lookup.value >= 1e6) {
      let s = number % lookup.value;
      let absNumber2 = Math.abs(number);
      let secondNumber = '';
      let lookup2 = suffixes
        .slice()
        .reverse()
        .find((item) => absNumber2 >= item.value);
      if (lookup2 && s > 0) {
        secondNumber = (s / lookup2.value).toFixed(0) + lookup2.symbol;
      }

      return (number / lookup.value).toFixed(0) + lookup.symbol + secondNumber;
    } else {
      return (number / lookup.value).toFixed(decimal) + lookup.symbol;
    }
  }

  return number.toString(); // Return original number if not large enough
}

// Example: Set a cookie named "userToken" with value "abc123" that expires in 7 days
function generatePermutations<T>(array: T[]): T[][] {
  const result: T[][] = [];

  function permute(arr: T[], current: T[] = []) {
    if (arr.length === 0) {
      result.push(current);
      return;
    }

    for (let i = 0; i < arr.length; i++) {
      const newArray = arr.slice(0, i).concat(arr.slice(i + 1));
      permute(newArray, current.concat([arr[i]]));
    }
  }

  permute(array);
  return result;
}

export function generateArrayFromNumber(number: number) {
  return String(number).split('').map(Number);
}
export function getUniquePermutations<T>(array: T[]): T[][] {
  if (array.length <= 1) return [];
  const permutations = generatePermutations(array);
  const uniquePermutations: string[] = [];

  permutations.forEach((permutation) => {
    const permutationStr = permutation.join(',');
    if (!uniquePermutations.includes(permutationStr)) {
      uniquePermutations.push(permutationStr);
    }
  });

  return uniquePermutations.map((permutationStr) =>
    permutationStr.split(',').map(Number),
  ) as T[][];
}

export function  showHiddenAsterick (str:string) {
  if(str == "" || str == null)  return "***";
  let strLength = str.length;
  return str.slice(0,2) +"*".repeat(strLength - 2) +str.slice(str.length - 1)
}

export function validatePassword(password: string): boolean {
  const hasMinimumLength = password.length >= 4;
  const hasNumber = /\d/.test(password); // Check for at least one digit
  const hasAlphabet = /[a-zA-Z]/.test(password); // Check for at least one alphabet

  return hasMinimumLength && hasNumber && hasAlphabet;
}

export function shortenString(str: string, maxLength: number): string {
  if (str.length > maxLength) {
    return `${str.slice(0, 3)}...${str.slice(-2)}`;
  }
  return str;
}

/// app config
export const loadingTimeout = 300; // unit mili second
export const lockTimeBeforeRound = 15; // unit minute
