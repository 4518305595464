import { Dialog,DialogTitle,DialogContent,DialogContentText,DialogActions,Button } from "@mui/material";
import { MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
interface iDialogBoxProps {
    isOpen : boolean,
    handleClose : MouseEventHandler,
    handleConfirm: MouseEventHandler,
    useHeader? : boolean

}

const DialogBox = (props:iDialogBoxProps) => {
    const {t}  =  useTranslation();
    const handleClose = props.handleClose;
    const handleConfirm = props.handleConfirm;
    const showHeader    = props.useHeader ||  false;
    return (
        <Dialog
        open={props.isOpen}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        {showHeader && <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Header

        </DialogTitle>}
        <DialogContent>
          <DialogContentText sx={{fontSize: 22, color:'black'}}>
            {t("bet_confirmation")}
          </DialogContentText>
        </DialogContent>
        <DialogActions >
          <Button  sx={{fontSize: 18,color:"red"}}  autoFocus onClick={handleClose}>
           {t("cancel")}
          </Button>
          <Button sx={{fontSize: 18,color:'green'}}  onClick={handleConfirm}>{t("confirm")}</Button>
        </DialogActions>
      </Dialog>
    )
}


export default DialogBox;