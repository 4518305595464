import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../Header";
import BackButton from "../BackButton";
import i18n from "../../i18n";
import { useState } from "react";
import { iUserInfo } from "../../interface/iUser";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ListItem,List,ListItemIcon } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
const Profile = (props:any) => {
    const userState:iUserInfo = useSelector((state:any) => (state.userState));
    
    const {t} = useTranslation();

    return (
        <>
        <Header title={t('personal_data')} back={<BackButton></BackButton>}></Header>
        <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <Link to={"/update-u"}>

        <div className='flex mx-4 justify-between px-4 py-3 my-2 shadow-sm rounded-lg border'>
          <div className='items-center flex'>
            {t('username')}
          </div>
          <ListItemIcon className='justify-end items-center'> {userState.username} <ChevronRight /></ListItemIcon>
        </div>
      </Link>
      <Link className="disabled" to={"/update-phone"}>

        <div className='flex mx-4 justify-between px-4 py-3 my-2 shadow-sm rounded-lg border'>
          <div className='items-center flex'>

            {t('phone')}
          </div>
          <ListItemIcon className='justify-end items-center'>  {userState.phone}  <ChevronRight /></ListItemIcon>
        </div>
      </Link>
      {/* <Link to={"/setting"}> */}
        <div className='flex mx-4 justify-between px-4 py-3 my-2 shadow-sm rounded-lg border'>
          <div className='items-center flex'>
            {t('ID')}
          </div>
          <ListItemIcon className='justify-end items-center pr-2'>  {userState.phone} </ListItemIcon>
        </div>
      {/* </Link> */}
    
      </List>
        </>

    )
}

export default Profile;