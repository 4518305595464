import BackButton from "./BackButton"
const Header = ({back,logo,title,children,cls}:any) => {
   return(
    <div className={`App-header ${cls}`}>
        {back && back}
        {children && children}
        {title &&  <div className="ngc-title">{title}</div>}
    </div>
   )
}

export default Header;