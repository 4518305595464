import * as React from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import {InfoOutlined,DraftsOutlined, WalletOutlined, AttachMoney, AttachMoneyOutlined, MilitaryTechOutlined, ArrowDownward, ArrowUpward, ReceiptOutlined, ChevronRight} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { ReceiptLongOutlined,SettingsOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Icon, IconButton } from '@mui/material';
import { mainColor } from '../color';
import { useSelector } from 'react-redux';
import { formatCurrency, formatCurrencyDecimal } from '../util';
import { iUserInfo } from '../interface/iUser';
import logo from "../assets/k24h.png";
import Header from './Header';
import avatar1 from "../assets/avatar/avatar-1.webp"
interface iMe {
  setBackDropOpen: Function
}

interface iMeHeader {
  logo ?: string,
}

export const MeHeader = ({logo}: iMeHeader) => {

  const {t} = useTranslation();
  return (
    <div className='flex App-header'>
    <div className='logo'>
      <img src={logo}></img><span>Lottery</span>
    </div>
    
    <Link className='!mr-4 text-white border-[1px] border-solid py-[4px] px-[15px] rounded-md' to={'/login'}>{t("login")}</Link>
    </div>
  )
}

export default function Me(props:iMe) {
  const {t} = useTranslation();
  const [open, setOpen] = React.useState(true);
  const userState:iUserInfo = useSelector((state:any) => (state.userState));
  const loginState = useSelector((state: any) => state.login);
  const {isAuthenticated} = loginState;
  const handleClick = () => {
    setOpen(!open);
  };
  
  React.useEffect(() => {
    props.setBackDropOpen(false);
  } , []);

  return (
    <div>
      {isAuthenticated ? <div className={`flex flex-col text-white bg-[#FB0605]`}>
        <div className={`py-4 bg-[${mainColor}] border-b-[1px] border-solid border-gray-50 border-opacity-50`}>
          <div className='flex px-4'>
              <Link to={"profile"}><img className='w-[47px]' src={avatar1}></img></Link>
              <div className='flex flex-col justify-start ml-4'>
                <div className='text-left'>{userState.username}</div>
                <div>{t('id')}: <span>{userState.phone}</span></div>
              </div>
          </div>
        </div>
        
        <div className={`flex justify-between py-4 px-4 bg-[${mainColor}]`}>
         
            <div className='flex flex-col w-1/2 justify-center'>
            <Link state={{tab:"wallet"}} to={'dep-with-trx'}>
              <div>
                  <IconButton><AttachMoneyOutlined></AttachMoneyOutlined></IconButton>
                  <span>{t('wallet')}</span>
              </div>
              <div><span>{formatCurrency(userState.balance)}</span></div>
            </Link>
            </div>
          <div className='flex flex-col w-1/2 justify-center'>
            <Link state={{tab:"bonus"}} to={'dep-with-trx'}>
              <div>
                <IconButton><MilitaryTechOutlined></MilitaryTechOutlined></IconButton>
                <span>{t('bonus')}</span>
              </div>
              <div><span>KHR </span>{formatCurrencyDecimal(userState.comm)}</div>
            </Link>
          </div>
        </div>

        <div className={`flex bg-red-700 px-4`}>
            <div className='w-1/2 justify-center'>
              <IconButton><ArrowDownward className='text-white'></ArrowDownward></IconButton>
              <button><Link to={`dep`}>{t("deposit")}</Link></button>
            </div>
           <div className='w-1/2 justify-center'>
            <IconButton><ArrowUpward  className='text-white'></ArrowUpward></IconButton>
            <button><Link to={'withdraw'}>{t("withdraw")}</Link></button>
           </div>
        </div>
      </div> : <MeHeader logo={logo}></MeHeader>}
      <List
      sx={{ width: '100%', bgcolor: 'background.paper' }}
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      <Link to={"/bet-trx"}>

        <div className='flex mx-4 justify-between px-4 py-3 my-2 shadow-sm rounded-lg border'>
          <div className='items-center flex'>
            <ListItemIcon>
                <ReceiptLongOutlined />
              </ListItemIcon> 
            {t('bet_transaction')}
          </div>
          <ListItemIcon className='justify-end items-center'> <ChevronRight /></ListItemIcon>
        </div>
      </Link>
      <Link to={"/dep-with-trx"}>

        <div className='flex mx-4 justify-between px-4 py-3 my-2 shadow-sm rounded-lg border'>
          <div className='items-center flex'>
            <ListItemIcon>
                <ReceiptOutlined />
              </ListItemIcon> 
            {t('transaction')}
          </div>
          <ListItemIcon className='justify-end items-center'> <ChevronRight /></ListItemIcon>
        </div>
      </Link>
      <Link to={"/setting"}>
        <div className='flex mx-4 justify-between px-4 py-3 my-2 shadow-sm rounded-lg border'>
          <div className='items-center flex'>
            <ListItemIcon>
                <SettingsOutlined />
              </ListItemIcon> 
            {t('setting')}
          </div>
          <ListItemIcon className='justify-end items-center'> <ChevronRight /></ListItemIcon>
        </div>
      </Link>
      

      {/* <Link to={"/dep-with-trx"}>
        <ListItemButton>
            <ListItemIcon>
              <ReceiptOutlined />
            </ListItemIcon>
            <ListItemText primary={t('transaction')} />
        </ListItemButton>
      </Link>

      <Link to={"/setting"}>
        <ListItemButton>
            <ListItemIcon>
              <SettingsOutlined />
            </ListItemIcon>
            <ListItemText primary={t('setting')} />
        </ListItemButton>
      </Link> */}

      </List>
    </div>
  );
}