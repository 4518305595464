import { ToastContainer,Zoom,Flip } from "react-toastify"
const MyToastContainer = () => {
    return (
        <ToastContainer 
        // position="bottom-right"
        className={"custom-toast-container"}
        autoClose={300}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        limit={1}
        pauseOnHover
          theme='dark'
          transition={Zoom}
  ></ToastContainer>
    )
}

export default MyToastContainer;