import { useTranslation } from "react-i18next";
import { mainColor } from "../../color";
import { MouseEventHandler } from "react";
import { formatCurrencyDecimal, selectedCurrency } from "../../util";

interface iProps {
    onSumbit: MouseEventHandler,
    onChange: Function,
    amount: number,
    max: number,
    min: number
}

const FormTranfer = (props:iProps) => {
    const {t} = useTranslation();
    return (
        <div className="px-4 bg-gray-50 flex flex-col my-1">
             <div className="flex rounded-lg shadow-sm mt-2">
                <span className="px-4 py-4 inline-flex items-center min-w-fit rounded-s-md border border-e-0 border-gray-200 text-sm bg-wh">{t("amount")}</span>
                <input type="number" value={props.amount == 0 ? "" : props.amount} onChange={(e) => {props.onChange(+e.target.value)}} placeholder={t("please_enter_amount")} className="py-3 px-4 focus:outline-none pe-11 block w-full border-gray-200 shadow-sm rounded-e-lg text-lg focus:z-10 focus-visible:shadow-none disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:ring-gray-600"/>
             </div>
            <span className="text-sm text-right text-blue-500 mb-2">{formatCurrencyDecimal(props.min) } - {formatCurrencyDecimal(props.max)} {selectedCurrency}</span>
            <div className="flex justify-between py-3 px-4 bg-white rounded-md">
                <div>{t("from")}</div>
                <div>Bonus</div>
            </div>

            <div className="flex justify-between my-2 py-3 bg-white rounded-md px-4">
                <div>{t("to")}</div>
                <div>Wallet</div>
            </div>

            <button onClick={props.onSumbit} className={`bg-[${mainColor}] px-6 py-2 mx-auto my-2 text-white rounded-md`}>{t("transfer")}</button>
        </div>
        
    );
}


export default FormTranfer;