// authMiddleware.js
import { loginAction,logOutAction } from "../state/action/login";
import http from "../http/Request";
import { setIsLoading } from "../state/action/app";
import { loadingTimeout } from "../util";
import { setUserInfo } from "../state/action/user";
import { iUserInfo } from "../interface/iUser";
export const checkAuthStatus = () => (dispatch:any, getState:any) => {
    dispatch(setIsLoading(true));

    const noAuthRoute = ['/register']; // skip check login route
    const pathname = window.location.pathname;
    const isNoAuth = !localStorage.getItem('token')
    const OnError = () => {
      dispatch(logOutAction());
    }
    
    const onResponse = (res:any) => {
     const info:iUserInfo = res.data;
     console.log(info, 'info');
     setTimeout(() => {
       dispatch(setIsLoading(false));
     },loadingTimeout)
     
      if(info.phone) {
        dispatch(setUserInfo(info));
        dispatch(loginAction(true));
      } else {
        dispatch(logOutAction());
      }

    }

    localStorage.getItem("token") && http.pos({path: '/user/verify',data: {},cb: onResponse, OnError: OnError});  // check skip  route
    
  };
  