import { Container } from "@mui/system";
import styled from "@emotion/styled";
const MainContainer = styled(Container)({
    display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    paddingLeft: '10px',
    paddingRight: "10px"
  });


  export default MainContainer;