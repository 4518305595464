import moment from "moment";

class Time  {
    getHM(timestamp:number) {
        timestamp = timestamp * 1000;
        const now = new Date(timestamp);
        const hours = String(now.getHours()).padStart(2, '0');
        const minutes = String(now.getMinutes()).padStart(2, '0');
        return {
            h: hours,
            m: minutes
        }

    }

    getCurrentTime () {
        return moment().format("X");
    }
}


export default Time;

