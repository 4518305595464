import { Key, MouseEventHandler, useEffect, useState } from "react";
import BackButton from "./BackButton";
import Header from "./Header";
import { useTranslation } from "react-i18next";
import { betStatus, formatCurrency, loadingTimeout, stringBetStatus } from "../util";
import moment from "moment";
import BetDetail from "./BetDetail";
import MyAxios from "../http/MyAxios";
import { LottoType } from "../util";
import NoRecordRound from "./element/NoRecordRound";
import HttpLoading from "./element/HttpLoading";
import { mainColor } from "../color";
import LoadingInsideDev from "./LoadingInsideDev";
import { color, height } from "@mui/system";
import { IconButton } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
interface BetTransaction {

}

const BetTransaction = ({}:BetTransaction) : JSX.Element => {
    const {t} = useTranslation();
    const [active,setActive] = useState("pending");
    const [content,setContent] = useState<[]>([]);
    const [summary,setSummary] = useState<any | null>(null);
    const [openedDetailId,setOpenedDetailId] = useState<number | null>(null);
    const [detail,setDetail] = useState<any>();
    const [backDropOpen, setBackDropOpen] = useState(false);
    let limit = 15;
    const http = new MyAxios();
    
    const onTab = ({id}:any) => {
        setBackDropOpen(true);
        setActive(id);
        setContent([]);
        http.get({path: "/betorder",query: {status: betStatus[id], limit: limit },cb: onResponse});
    }
    
    const onResponse = (res:any) => {
       
        let timer = setTimeout(() => {
            clearTimeout(timer);
            setBackDropOpen(false);
            let data = res.data;
            if(res.statusCode == 200) {
                setContent(data);
                
            } else {
                console.log("no");
            }

        }, loadingTimeout)
    }

    const onResponseDetail = (res:any) => {
        let data = res.data;
        // let data = {
        //     round_id: openedDetailId,
        //     bet: 878,
        //     luck_number:345,
        //     status: 1,
        //     win: 9000,
        //     product_time: 1704116501,
        //     channel: "A",
        //     lotto_type: "3D",
        //     bet_time: "1704116501"
        // }
        console.log(res.data,'res.data');
        if(res.statusCode == 200) {
            // data.bet_time = res.data.bet_time;
            setDetail(data);
        } else {
           
        }
    }


    useEffect(() => {
        http.get({path: "/betorder",query: {status: betStatus[active], limit: limit },cb: onResponse});
    }, [])

    // useEffect(() => {

    //     openedDetailId && http.get({path: "/betorder" + `/${openedDetailId}`,cb: onResponseDetail});

    // }, [openedDetailId])

    const onOpenDetail = ({round_id,lotto_type,bet_amount,win_amount}:any) => {
        setBackDropOpen(true);
        setSummary({
            round_id: round_id,
            bet_amount: bet_amount,
            win_amount: win_amount
        });
        // console.log(round_id,lotto_type,'onOpenDetail');
        let cbBetGroup = (e:any) => {
            setBackDropOpen(false);
            if(e.statusCode == 200) {
                setDetail(e.data);
            }
            console.log(e,'e');
        }   

        http.get({path: "/betorder/bet-group",query: {round_id:round_id, lotto_type: lotto_type,status: betStatus[active] },cb: cbBetGroup});
        // setOpenedDetailId(id);
    }

    const onCloseDetail = () => {
        console.log("close");
        // setOpenedDetailId(null);
        setDetail(null);
    }

    return (
        <>
        <Header cls="w-full z-10 bg-white" title={t('bet_transaction')} back={<BackButton></BackButton>}></Header>
        <div>
            <div className="grid grid-flow-col w-full z-10 bg-white  ">
                { stringBetStatus && stringBetStatus.map((status:string) => (<div className={`py-4 border-y-[1px] border-solid ${active == status ? 'text-red-600 border-b-red-600' : ''}`} onClick={() => { onTab({id: status})}} key={status}>{t(`${status}`)}</div>))  }
            </div>
            <div className="overflow-y-scroll pb-4" style={{height: "calc(100vh - 118px)"}}>
                {backDropOpen &&  <LoadingInsideDev></LoadingInsideDev>}
                <BetContent name={active} onOpenDetail={onOpenDetail} data={content}></BetContent>
            </div>
            
            { detail && <BetDetail onCloseDetail={onCloseDetail}  summary={summary} data={detail}></BetDetail>}
        </div>
        {/* <HttpLoading backDropOpen={backDropOpen}></HttpLoading> */}
        </>

    )
}

interface iBetContent {
    data?: [],
    name: string
    onOpenDetail: Function,
}

const BetContent = (props:iBetContent) => {
    let data = props.data;
    console.log(data,'BetContent');
    // let data = [
    //     {
    //         lotto_type: "3D",
    //         win: "",
    //         product_time: 1704116501
    //     },
    //     {
    //         lotto_type: "2D",
    //         win: "5000",
    //         product_time: 1706126502
    //     },
    //     {
    //         lotto_type: "3D",
    //         win: "-5000",
    //         product_time: 1706114503
    //     },
    //     {
    //         lotto_type: "3D",
    //         win: "-5000",
    //         product_time: 1706116504
    //     },
    //     {
    //         lotto_type: "3D",
    //         win: "5000",
    //         product_time: 1706116505
    //     },
    //     {
    //         lotto_type: "3D",
    //         win: "5000",
    //         product_time: 1706116506
    //     },
    //     {
    //         lotto_type: "3D",
    //         win: "5000",
    //         product_time: 1706116507
    //     },
    //     {
    //         lotto_type: "3D",
    //         win: "5000",
    //         product_time: 1706116508
    //     },
    //     {
    //         lotto_type: "3D",
    //         win: "5000",
    //         product_time: 1706116509
    //     },
    //     {
    //         lotto_type: "3D",
    //         win: "5000",
    //         product_time: 1706116500
    //     },
    //     {
    //         lotto_type: "3D",
    //         win: null,
    //         product_time: 1706116500
    //     }
    // ]
    return (
        <div key={'bet-transaction'} className="flex flex-col mx-4 gap-1 mt-2">
           {data && data.length > 0 ? data.map((item:any) => (<BetList name={props.name} key={item.id}  onOpenDetail={() => {
            props.onOpenDetail({round_id: item.round_id,lotto_type: item.lotto_type,bet_amount: item._sum.bet_amount,win_amount: item._sum.win_amount});
           }} data={item}></BetList>)) : <NoRecordRound></NoRecordRound>}
        </div>
    )
}

interface iBetList {
    data: {
        lotto_type: string,
        round_id: number
        _sum: {
            win_amount: number,
            bet_amount: number
        }

    },
    name:string,
    onOpenDetail: MouseEventHandler,
}


const BetList = (props:iBetList) => {
    let statusColor = '';
    const data = props.data;
    let win = data._sum.win_amount;
    let betAmount = data._sum.bet_amount;
    // if(data._sum.win_amount > 0) {
    //     statusColor = "text-[#50F242]";
    // } else if(!data._sum.win_amount) {
    //     statusColor = "text-[gray]";
    // } else {
    //     statusColor = "text-[#FB0000]";
    // }

    if(props.name == 'pending') {
        statusColor = "text-[#FBEB00]";
    } else if(props.name == 'lose') {
        statusColor = "text-[red]";
    } else if(props.name == 'win') {
        statusColor = "text-[#1711dc]";
        betAmount = data._sum.win_amount;
    } else {
        if(data._sum.win_amount > 0) {
            statusColor = "text-[#50F242]";
        } else if(!data._sum.win_amount) {
            statusColor = "text-[green]";
        } else {
            statusColor = "text-[#FB0000]";
        }
    }
    

    return (
        <div onClick={props.onOpenDetail} className={` grid grid-cols-9 justify-between items-center flex-row py-2 border-[1px] border-solid rounded-xl bg-[#f1a6a8] px-4`}>
            {/* <div className={`w-[55px] h-[55px] border-[1px] rounded-full bg-[${mainColor}] text-white text-2xl border-white font-bold pt-2`}>{LottoType[+data.lotto_type]}</div> */}


            <div className="text-black text-lg col-span-5 text-left">{moment(data.round_id * 1000).format("YYYY/MM/DD h:mm A")}</div>
            <div className={`${statusColor} text-lg col-span-3 text-left`}>{formatCurrency(betAmount)}</div>
            <IconButton><ChevronRight sx={{color: 'red'}}></ChevronRight></IconButton>
            
        </div>
    )
}

export default BetTransaction;