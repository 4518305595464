import BackButton from "./BackButton";
import Header from "./Header";
import { useTranslation } from "react-i18next";
import CountdownTimer from "./CountDownTimer";
import Time from "./element/Time";
import ButtonStyled from "./element/ButtonStyles";
import MainContainer from "./element/mainContainer";
import { Link } from "react-router-dom";
import { useState,useEffect } from "react";
import http from "../http/Request";
import LoadingSpinner from "./Loading";
import { useSelector } from "react-redux";
import { iRoundList } from "../interface/iProduct";
import { generateArrayFromNumber, loadingTimeout, lockTimeBeforeRound, lottoChannel } from "../util";
import Ball from "./element/Ball";
import { mainColor } from "../color";
import moment from "moment";
import HttpLoading from "./element/HttpLoading";
const RoundList = () : JSX.Element => {
    const {t} = useTranslation();
    const appState = useSelector((state:any) => state.app);
    const time = new Time();
    const [backDropOpen, setBackDropOpen] = useState(true);
    const [roundList,setRoundList] = useState<any>([]);
    
    let cb = (res:any) =>  {

        let data = res && res.data;
        let closedRound = [];
        let dataArr = [];
        for(let i = 0; i < data.length; i++) {
            if(+time.getCurrentTime() < data[i].product_time)  {
                dataArr.push(data[i]);
            } else {
                closedRound.push(data[i]);
            }
        }
        
        let timer = setTimeout(() => {
            clearTimeout(timer);
            setBackDropOpen(false);
        }, loadingTimeout)
        setRoundList([...dataArr, ...closedRound]);
      }
      
    useEffect(() => {
        http.get({path: "/product",query: {startDate: moment().startOf("day").format("X"),endDate: moment().endOf("day").format("X")},cb: cb});
      },[])
    
    const NumberList = (props:any) => {

        let result = props.result && props.result;
        let keys  = Object.keys(result);
        let value = Object.values(result);
        let num = props.result[keys[0]] && String(props.result[keys[0]]).split("").map(String);
        let num1 = props.result[keys[1]] && String(props.result[keys[1]]).split("").map(String);
        console.log(num,' umb',result);
        // num = num || ["0", "0","0","0","0"];
        
        return (<div className="">
            <div className="flex justify-center items-center gap-1 font-bold text-lg ">
                <div className="">{keys[0]}</div> 
                <div className="flex text-[#1711dc]">
                    {num ? num.map((n:string) => (n)) : " ---::---"}
                </div>
            </div>
            <div className="flex justify-center font-bold text-lg  gap-1  items-start">
                <div>{keys[1]}</div> 
                <div className="flex text-[#1711dc]">  {num1 ? num1.map((n:string) => (n)) : " ---::---"}</div>
            </div>
        </div>
        )
    }

    const RoundItem = ({data}:any) => {
        const remainingTime = (data.product_time - (lockTimeBeforeRound) * 60); // second unit
        const remainingTimeToShowResult = data.product_time;
        const [isClosed,setIsClosed] = useState(false);
        type iResuls = {[key:string]: null};
        let   result:iResuls = {
            "A": data["A"],
            "C": data["C"],
        };

        let result1 = {
            "B": data["B"],
            "D": data["D"]
        }
        
        useEffect(() => {
            if(+time.getCurrentTime() > +remainingTime) {
                setIsClosed(true);
            }

        }, [])

        const NumberBox = ({value}:any) => {
            return (
                <div className={`border-[1px] font-bold text-xl p-1 mx-2 border-solid`}>{value}</div>
            )
        }

        return (
            <div className="border-b-[1px] border-solid">
            <div className="grid grid-cols-4 py-4">
                <div className="flex flex-col">
                    <div>{t("round")}</div>
                    <div>{data.round_id}</div>
                </div>

                <div className={`flex justify-center items-center text-[${mainColor}]`}>
                    <NumberBox value={time.getHM(+data.product_time).h}></NumberBox> :
                    <NumberBox value={time.getHM(+data.product_time).m}></NumberBox>
                </div>

                    {
                        isClosed ? <NumberList result={result} className=""></NumberList> : <div className="flex flex-col">
                        <div>{t("close_time")}</div>
                        <CountdownTimer targetTimestamp={remainingTime} cb={setIsClosed} cls="text-lg"></CountdownTimer>
                    </div>
                    }

                <div className="flex flex-col justify-center">
                    {isClosed ? <NumberList result={result1}></NumberList> : <Link to={'/round-detail?id=' + data.id} state={{id: data.id}}><ButtonStyled>{t("play")}</ButtonStyled></Link>}
                </div>
            </div>
        </div>
        )
    }
    if(backDropOpen) {
        return  <HttpLoading backDropOpen={backDropOpen}></HttpLoading>;
    }

    return (
        <>
       
        <Header title={t('ngc_lotto')} back={<BackButton></BackButton>}></Header>
        {appState.isLoading && <LoadingSpinner></LoadingSpinner>}
        <MainContainer>
            <div className="flex flex-col">
                
                {roundList && roundList.map((item:any,index:number) => (
                    <RoundItem key={`round-${index}`}  data={item}></RoundItem>
                ))}

                {
                    !roundList.length && <>no Active Round</>
                }

            </div>
        </MainContainer>
        </>

    )
}

export default RoundList;