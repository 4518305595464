// Keypad.tsx
import React, { useState } from 'react';
import { mainColor } from '../../color';
import { useDispatch } from 'react-redux';
import { keyPadResult,clearResult } from '../../state/action/keypad';
import { useSelector } from 'react-redux';
import { lottoTypeLuckyNumLength } from '../../util';
import Kalert from '../element/Alter';
const Keypad1: React.FC<{cls?:string}> = ({cls}) => {
  // var [betNum, setInput] = useState<number[]>([]);
    
  const state = useSelector((state:any) => state );
  const {results} = state.keypad;
  const betBox = state.betbox;
  const {selectedLottoType} = betBox;
  // console.log(results,'result',betBox);
  const dispatch = useDispatch();
  const betNumLength = 5;
  const handleButtonClick = (value: string) => {
    // setInput([...betNum,+value])
   
    if(results.length >= lottoTypeLuckyNumLength[selectedLottoType] ) {
      // return <Kalert msg='can not enter more than 2' type='error'></Kalert>
    } else {
      dispatch(keyPadResult(+value))
    }
    if(results.length > betNumLength - 1) dispatch(clearResult());
  };

  const handleClearButtonClick = () => {
    dispatch(clearResult());
  };

  return (
    <div className={`flex flex-col max-w-md mx-auto py-2 space-y-2 ${cls}`}>
      {/* <input
        type="text"
        value={input}
        className="border p-2 bg-gradient-to-r from-red-600 via-red-500 to-red-400 rounded-md text-center focus:outline-none"
        readOnly
      /> */}

      <div className='grid grid-cols-5 gap-2 border-[1px] border-solid h-[46px]'>
        {results.map((i:number,index:number) => (<button key={`num-${index}-${i}`} className={` ${index <= 1 ? "border-green-500 text-green-300" : `border-[${mainColor}] text-rose-500 `} text-[30px] animate-pulse  font-bold border-[1px] text-lg rounded-md p-2`}>{i}</button>))}
      </div>
      <div className="grid grid-cols-6 gap-2">
        {['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'លុប'].map((value, index) => (
          <button
            key={index}
            className={`${value == 'លុប' ? `rounded-xl py-2 px-8 w-[108px] border-[1px] border-[#FB0605] text-center` : "rounded-full p-2 w-[45px]"} h-[45px]  border-[1px] border-solid ${results && results.includes(+value) ? `bg-[red]` : ""} text-lg  focus:outline-none`}
            onClick={() => (value === 'លុប' ? handleClearButtonClick() : handleButtonClick(value))}
          >
            {value}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Keypad1;
