import React, { useRef, useEffect } from 'react';
import Swiper from 'swiper';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import banner1 from "../../assets/slide/banner-1.jpg";
import banner2 from "../../assets/slide/banner-2.jpg";
import banner3 from "../../assets/slide/banner-3.jpg";
import banner4 from "../../assets/slide/banner-4.jpg";
import banner5 from "../../assets/slide/banner-5.jpg";
import banner6 from "../../assets/slide/banner-6.jpg";
const MySlide = () => {
  const swiperRef = useRef(null);

  useEffect(() => {
    const swiper:any = swiperRef.current && new Swiper(swiperRef.current, {
      modules: [Autoplay],
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      autoplay: {
        delay: 3000, // Adjust the delay (in milliseconds) between each slide transition
        disableOnInteraction: false, // Enable autoplay even when user interacts with swiper
      }
    });

    return () => {
      swiper.destroy();
    };
  }, []);

  return (
    <div className="swiper-container" ref={swiperRef}>
      <div className="swiper-wrapper">
        {/* Your slides here */}
        <div className="swiper-slide"><img width={'100%'} height={'100%'} src={banner1}></img></div>
        <div className="swiper-slide"><img width={'100%'} height={'100%'} src={banner2}></img></div>
        <div className="swiper-slide"><img width={'100%'} height={'100%'} src={banner3}></img></div>
        <div className="swiper-slide"><img width={'100%'} height={'100%'} src={banner4}></img></div>
        <div className="swiper-slide"><img width={'100%'} height={'100%'} src={banner5}></img></div>
        <div className="swiper-slide"><img width={'100%'} height={'100%'} src={banner6}></img></div>
        {/* Add more slides as needed */}
      </div>
    </div>
  );
};

export default MySlide;
