import { useTranslation } from "react-i18next";
import Header from "./Header";
import BackButton from "./BackButton";
import { defaultAmountDeposit, formatCurrency, formatCurrencyDecimal } from "../util";
import { selectedCurrency } from "../util";
import { useState,useEffect } from "react";
import { mainColor } from "../color";
import ABAKHQR  from "../assets/payment/KHQR.jpg"
import ButtonStyled from "./element/ButtonStyles";
import { toast } from 'react-toastify';
import MyToastContainer from "./MyToastContainer";
import MyAxios from "../http/MyAxios";
import { iResponse } from "../interface/IAPIResponse";
import { iUserInfo } from "../interface/iUser";
import { useSelector } from "react-redux";
import { TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { BtnSeeTransaction } from "./element/BtnSeeTransaction";
import { formatNumber } from "../util";
import Dropdown from "./element/Dropdown";
interface iWithdrawal {

}

const Deposit = (props:iWithdrawal):JSX.Element => {
    const http = new MyAxios();
    const {t} = useTranslation();
    const [amount ,setAmount] = useState(0);
    const userState:iUserInfo = useSelector((state:any) => (state.userState));
    const [insuffient,setIsinsuffient] = useState(false)
    const [bankList,setBankList] = useState([])
    const [selectedBank,setselectedBank] = useState(0)
    const onClickAmount = (amount:number) => {
        setAmount(amount);
        setIsinsuffient(amount > userState.balance); 
       
    }

    const onBetResponse = (res:iResponse) => {
        if(!(res.statusCode == 200))  {
            toast(t("something_went_wrong"), {type: "success"}); return;
        }

        toast(t("submit_successfully"), {type: "success"})

    }

    const onClickMax = () => {
        setAmount(userState.balance);
    }

    const onBankListsponse = (res:any) => {
        if(res.status == 1) {
            setBankList(res.data);
        }
    }

    const onSumit = () => {
        if(!amount) {
            toast(t("enter_balance"), {type: "error"});

            return;
        }

        if(!selectedBank) {
            toast(t("select_bank"), {type: "error"});

            return;
        }

        if(amount > userState.balance) {
            toast(t("insufficient_balance"), {type: "error"});return;
        }

        let data = {
            type: 2,
            payment_id: selectedBank,
            amount: amount
        }


        http.pos({path: 'deposits', data: data, cb: onBetResponse });

    }

    useEffect(() => {
        http.get({path: 'payment-detail', cb: onBankListsponse });
    }, [])
    
    return (
        <div>
        <Header title={t('withdrawal')} back={<BackButton></BackButton>}></Header>
        <div className="mx-4">
            <div className="py-4">1. {t("please_fill_payment_detail_to_widthdraw")}</div>
            
            <div>
                <BtnSeeTransaction></BtnSeeTransaction>
                <div className="relative mt-1 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <span className="text-gray-500 sm:text-sm">KHR</span>
                </div>
                <input type="number" name="price" onChange={(e) => {
                    setAmount(+e.target.value);
                    }} id="price" placeholder={t("please_enter_amount")} value={amount} className={` ${amount > userState.balance ? "ring-red-500" : "ring-gray-300"} block w-full focus:outline-none rounded-md border-0 py-1.5 pl-14 pr-20 text-gray-900 ring-1 ring-inset  placeholder:text-gray-400 sm:text-sm sm:leading-6`} />
               
            </div>
           
            <div className="flex justify-between"> <div className="text-sm text-left text-green-600">{formatCurrency(userState.balance)}</div> <a onClick={onClickMax} className="text-right underline text-sm">Max</a></div>
            </div>

            <div className="grid grid-cols-3 gap-1 mt-5">
                {
                    defaultAmountDeposit.map((a:number) => (
                        <button onClick={() => { onClickAmount(a) }} className={`border-[1px] px-4 py-2 ${amount == a ? `bg-[${mainColor}] text-white` : '' }`}>{formatNumber(a)}</button>
                    ))
                }
            </div>
            
            <div className="py-4">2. {t("bank_detail")}</div>

            <div>
                {/* <div className="mb-5">{t("bank_detail")}</div> */}
                <Link to={'/payment'} className="flex underline justify-end text-green-400">{t("add_new")}</Link>
                <Dropdown selected={selectedBank} list={bankList} setSelected={setselectedBank}></Dropdown>
            </div>
            
            
            <button onClick={onSumit} className={`fixed text-white bottom-0 left-0 bg-[${mainColor}] py-3 w-full rounded-sm`}>{t("submit")}</button>

        </div>
        <MyToastContainer></MyToastContainer>
    </div>
    )
}

export default Deposit;