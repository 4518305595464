import { MouseEventHandler, useEffect, useState } from "react";
import { betAmountList, formatCurrency, formatCurrencyDecimal, formatNumber, winningMultiplication } from "../../util";
import { IconButton } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { lottoTypeLuckyNumLength } from "../../util";
import { onSetBetAmount } from "../../state/action/betBox";
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
interface iBetBox {
  reverse: number,
  // onBet  : MouseEventHandler,
  handleOpenDialog: MouseEventHandler,
  winUpTo: number,
  isClose?: boolean
}

const BetsBox = (props: iBetBox) => {
  const decimal = 2;
  const { t } = useTranslation();
  const state = useSelector((state: any) => (state));
  const { totalBetTimes, betAmount } = state.betbox;
  const selectedLotto = state.betbox.selectedChannel;
  const dispatch = useDispatch();
  const [isShowList, setShowList] = useState<boolean>(false);

  const onClick = () => {
    setShowList(!isShowList);
  }

  // useEffect(() => {
  //   // setWinUpTo(betAmount * winningMultiplication[selectedLottoType]  * (totalBetTimes * selectedLotto.length));
  // },[selectedLotto,totalBetTimes, selectedLottoType])

  const onSelect = (bet: number) => {
    // props.seBetAmount(bet);
    dispatch(onSetBetAmount(bet));
    // setWinUpTo(bet * winningMultiplication[selectedLottoType] * (totalBetTimes * selectedLotto.length ));
    setShowList(false);
  }

  const BetAmountListItem: React.FC<{ item: number[] }> = ({ item }) => {
    return (
      <div className='absolute bg-white border-solid border-[1px] p-1 bottom-0'>
        {item && item.map((i, index) => (
          <div key={`bet-amount-list-${index}`} onClick={() => { onSelect(i) }} className='border-b-[1px] w-full text-lg'>{formatNumber(i, decimal)}</div>
        ))}
      </div>
    )
  }

  return (
    <>
      <div className='flex flex-col'>
        <div className="text-lg">{t('win_up_to')}</div>
        <div>
          <span className="text-sm"></span> <span className="text-lg">{formatCurrency(props.winUpTo)}</span>
        </div>
      </div>

      <div className="grid grid-cols-5 border-[1px] py-1 border-solid">

        {isShowList && <BetAmountListItem item={betAmountList}></BetAmountListItem>}
        <div className="flex col-span-2 flex-col items-center" onClick={onClick}>
          <span className="pb-1">{t("amount")}</span>
          <div className="flex justify-center items-center">
            <span className="text-sm"><span className="text-lg">{formatNumber(betAmount, decimal)}</span></span>
            <IconButton sx={{ padding: 0 }}><ChevronRight></ChevronRight></IconButton>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <span className="pb-1">{t('times')}</span>
          {totalBetTimes * selectedLotto.length}
        </div>
        <div className="flex flex-col">
          <span className="pb-1">{t('total')}</span>
          <span>{formatNumber(((totalBetTimes * selectedLotto.length) * betAmount), decimal)}</span>
        </div>
        {props.isClose ? <button onClick={props.handleOpenDialog} className='py-[2px] px-6 border-[1px] border-solid border-red bg-[#FB0605] rounded-lg text-white h-full'>{t('closed')}</button> : <button onClick={props.handleOpenDialog} className='py-[2px] px-6 border-[1px] border-solid border-red bg-[#FB0605] rounded-lg text-white h-full'>{t('play')}</button>}
      </div>
    </>
  )
}

export default BetsBox;