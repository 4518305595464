import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Keypad1 from './keypad/KeyPad1';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { IconButton } from '@mui/material';
import CountdownTimer from './CountDownTimer';
import { ChevronRight } from '@mui/icons-material';
import GroupIcon from '@mui/icons-material/Group';
import { Link, useNavigate } from 'react-router-dom';
import LottoChannel from './element/LottoType';
import { LottoType, UserComm, betAmountList, formatAmountAsK, formatCurrencyDecimal, loadingTimeout, lockTimeBeforeRound, selectedCurrency, showHiddenAsterick } from '../util';
import { winningMultiplication,formatCurrency } from '../util';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { onSetBetAmount,onSetBetTimes,onSetChannel, onSetLottoType } from '../state/action/betBox';
import { useDispatch } from 'react-redux';
import http from '../http/Request';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { LottoType as LottoTypeList  } from '../util';
import ItemList from './element/ItemList';
import { iBetBox, iBetRequest } from '../interface/iBet';
import DialogBox from './element/DialogBox';
import { toast } from 'react-toastify';
import { lottoTypeLuckyNumLength } from '../util';
import MySlide from './element/MySlide';
import 'react-toastify/dist/ReactToastify.css';
import {clearResult, setKeyPad } from '../state/action/keypad';
import MyToastContainer from './MyToastContainer';
import Balance from './element/Balance';
import MyAxios from '../http/MyAxios';
import axios from 'axios';
import NoAuthAxios from '../http/Request';
import { setIsLoading } from '../state/action/app';
import { updateBalance, updateComm } from '../state/action/user';
import HttpLoading from './element/HttpLoading';
import { iHomeData, iHomeProduct } from '../interface/iHome';
import TodayResult from './element/TodayResult';
interface HomeHeader {
  logo ?: string,
  balance: number,
  currencySign?: string
}

interface iHomePage {
  header?: JSX.Element
  setBackDropOpen: Function
}

const bull = (
  <Box
    component="span"
    sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
  >
  </Box>
);

export const HomeHeader = ({logo,balance,currencySign}: HomeHeader) => {
  console.log(balance,"HomeHeader")
  let b = balance && balance > 0 ? balance : 0;
  const loginState = useSelector((state: any) => state.login);
  const {isAuthenticated} = loginState;
  const {t} = useTranslation();
  return (
    <>
    <div className='logo'>
      <img src={logo}></img><span>Lottery</span>
    </div>
    
    { isAuthenticated ? <Balance balance={b}></Balance> : <Link className='!mr-4 text-white border-[1px] border-solid py-[4px] px-[15px] rounded-md' to={'/login'}>{t("login")}</Link>}
    </>
  )
}

const BetsBox = (props:iBetBox) => {

  const [isShowList,setShowList] = useState<boolean>(false);
  const  state = useSelector((state:any) => (state));
  const {betbox} = state;
  const {betAmount, selectedLottoType, selectedChannel ,totalBetTimes} = betbox;
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const onClick  =  () => {
    setShowList(!isShowList);
  }

  const onSelect = (bet:number) => {
    console.log(bet,'select Bet amount');
    dispatch(onSetBetAmount(bet));
    props.setWinUpTo(bet * winningMultiplication[selectedLottoType] * selectedChannel.length);
    setShowList(false);
  }

  return (
    <>
      <div className='flex flex-col'> 
        <div>{t('win_up_to')}</div> 
          <div>
            <span><span className='text-red-600'>{selectedCurrency}</span> {formatAmountAsK(props.winUpTo)}</span>
          </div>
      </div>
      <div>
        <div>{t("total")}</div>
        <span className='text-red-600'>{selectedCurrency}</span> {formatAmountAsK(betAmount * totalBetTimes * selectedChannel.length)}
      </div>
      <div className='flex gap-2'>  
        {isShowList && <ItemList onSelect={onSelect} item={betAmountList}></ItemList> }
        <div onClick={onClick}>
          <span><span className='text-red-600'>{selectedCurrency}</span> {formatAmountAsK(betAmount)}</span>
          <IconButton><ChevronRight></ChevronRight></IconButton>
        </div>
        <button onClick={props.handleOpenDialog}  className='py-[2px] px-6 border-[1px] border-solid border-red bg-[#FB0605] rounded-lg text-white'>{t('play')}</button>
      </div>
    </>
  )
}

export const HomePage =  ({header,setBackDropOpen}:iHomePage) => { // main
  const {t} = useTranslation();
  const [prouduct,setProduct] = useState<iHomeProduct>();
  const [homeData,setHomeData] = useState<iHomeData>({lastResult: [{round_id:null, product_time: (Math.round(new Date().getTime() / 1000)).toString(),A: null,B:null, C:null,D:null}],user:0,topWinner: []});
  const [showLottoList,setToggleTottoList] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const  state = useSelector((state:any) => (state));
  const selectedLotto = state.betbox.selectedChannel;
  const selectedLottoType = state.betbox.selectedLottoType;
  const totalBetTimes = state.betbox.totalBetTimes;
  const betAmount = state.betbox.betAmount;
  const luckyNum =  state.keypad.results;
  const loginState = useSelector((state: any) => state.login);
  const {isAuthenticated} = loginState;
  const dispatch = useDispatch();
  const http:MyAxios = new MyAxios()
  const [remainingTime,setTimeRemaining] = useState<number>(0)
  const [winUpTo,setWinUpTo]  = useState<number>(betAmount * winningMultiplication[selectedLottoType] * selectedLotto.length);

  const navigate = useNavigate();
  const handleOpenDialog = () => {
    if(luckyNum.length <= 0 || luckyNum.length < lottoTypeLuckyNumLength[selectedLottoType]) {
      toast(t("please_select_lucky_num"),{type: "error"}); return;
    }
    
    if(selectedLotto.length <= 0 ) {
      toast(t("please_select_channel"));return;
    }

    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const confirmBet = () => {
    setOpenDialog(false);
    let totalBetAmount = betAmount * totalBetTimes * selectedLotto.length;
    if(!isAuthenticated)  {navigate("/login")}
    if(state.userState.balance < totalBetAmount) { // 
      toast(t("no_balance"), {type: "error"}); return;
    }

    onBet();
  }

  const onClickLottoList = () => {
    setToggleTottoList(!showLottoList);
  }
  const resetvalue = () => {
    dispatch(clearResult());
    dispatch(onSetBetTimes(1))
    // setreverseResult([]);
  }
  const onBet = () => {


    if(!prouduct?.round_id) {  // if no product alert message
      toast(t("round_already_closed"), {type: "error"});return;
    }

    
    setBackDropOpen(true);

    let betData    :iBetRequest = {
      round_id     : prouduct.round_id,
      bet_amount   : betAmount,
      lotto_channel: selectedLotto.join(','),
      lucky_num    : luckyNum.join(','),
      lotto_type   : selectedLottoType,
      bet_time     : Math.round((new Date().getTime() / 1000)).toString(),
      totalBetTimes: totalBetTimes * selectedLotto.length,
      is_reverse   : false
    }

    const onBetResponse = (e:any) => {
      setBackDropOpen(false)
      if(e.statusCode == 200) {
        let totalBetAmount = betAmount * totalBetTimes * selectedLotto.length;
        let comm = totalBetAmount * UserComm / 100; // commistion pay to player
        dispatch(updateComm('+',comm));
        dispatch(updateBalance(-totalBetAmount));
        resetvalue();
        toast(t("place_success"), {type: "success"});
      } else {
        toast(t("place_error"), {type: "error"});
      }

    }

    http.pos({path: 'betorder/make-bet', data: betData, cb: onBetResponse });

    console.log("betData",betData);
  }

  const onSelect = (name:string,index:number) => {
    // console.log(name,index,'index and name');
   
    dispatch(setKeyPad(luckyNum.slice(0,lottoTypeLuckyNumLength[index]))); // slice clear if play chnage select from small to big (exp: switch from 2D to 5D)
    dispatch(onSetLottoType(index));
    setToggleTottoList(false);
  }

  // let onProductRes = (res:{data: iHomeProduct[]}) =>  {
  //   // if(state.app.isLoading) {
  //     setBackDropOpen(true);
  //    let timer = setTimeout(() => {
  //     setBackDropOpen(false);
  //     clearTimeout(timer);
  //     dispatch(setIsLoading(false));
  //    } , loadingTimeout);
  //   // }

  //   setProduct(res && res.data[0]);
  // }
  
  let onHomeDataRes = (res:{data: iHomeData}) =>  {
    // if(state.app.isLoading) {
      setBackDropOpen(true);
     let timer = setTimeout(() => {
      setBackDropOpen(false);
      clearTimeout(timer);
      dispatch(setIsLoading(false));
     } , loadingTimeout);
    // }
     console.log(res.data);
    setHomeData(res && res.data);
  }
  
  
  React.useEffect(() => {
    if(prouduct) setTimeRemaining(+prouduct.product_time);
  },[prouduct])


  React.useEffect(() => {

    // http.get({path: "/product",query: {state: 3},cb: onProductRes});

    http.get({path: "/home",cb: onHomeDataRes});
    
  },[])

  return (
    <div style={{margin: "auto 10px",marginTop: "75px;"}}>
      <Card  sx={{ minWidth: 275 ,margin: "10px auto", marginTop: "70px;"}}>
        {/* <CardContent sx={{padding: 0}} style={{ paddingBottom: '0px !important' }}> */}
          
            {/* <Slider></Slider> */}
            <MySlide></MySlide>
        {/* </CardContent> */}
      </Card>
      <div className='flex justify-between pb-4 pt-2'>
          <div className='text-2xl flex items-center'>{t("lottery")} <span className={`text-[#FB0605]`}>{t('k24h')}</span></div>
          <Link className='px-6 bg-[#FB0605] rounded-lg text-white py-2 border-[1px] border-solid' to={'round-list'}>{t("go_to_play")}</Link>
      </div>
      <Card  variant="outlined" sx={{ minWidth: 275 }}>
      <div className='border-b-[1px] border-solid'>
           {<TodayResult product={homeData.lastResult[0]}></TodayResult>}   
                {/* <Keypad1></Keypad1> */}
            </div>
        {/* <CardContent>
        <div className='flex justify-between'>
            <BetsBox winUpTo={winUpTo} setWinUpTo={setWinUpTo} handleOpenDialog={handleOpenDialog} onBet={onBet}></BetsBox>
        </div>
        </CardContent> */}

        <CardContent>
        <div className='flex flex-col justify-start gap-3'>

        <div className='flex gap-2'>  
            <IconButton sx={{padding:0}}><GroupIcon></GroupIcon></IconButton>
            <span>{homeData?.user} {prouduct && +prouduct.product_time}</span> 
            <span>អ្នកចូលរួម</span>
          </div>

          <div className='flex flex-col justify-start items-start'> 
                <span>អ្នកឈ្នះ</span> 
                {homeData.topWinner.length > 0 
                // if
                ? <div className='flex flex-col gap-1 text-left'>
                    {
                      homeData.topWinner.map((e:any) => (<div>
                      {/* <span>{showHiddenAsterick(e.user.username)}</span>  */}
                      <span>{formatCurrencyDecimal(e.win_amount)}</span> 
                      <span>({LottoType[e.lotto_type]})</span></div> ))
                    }
                </div>  
                //else
                : <span>---::---</span> }

          </div>
        </div>
        </CardContent>
      </Card>

      <DialogBox handleConfirm = {confirmBet} handleClose={handleCloseDialog} isOpen={openDialog}></DialogBox>
      <MyToastContainer></MyToastContainer>
    </div>
  );
}
