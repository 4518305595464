
const Loading =  () => {
    return (
      <div className="w-12 h-12 z-30 rounded-full animate-spin border-x-2 border-dashed border-blue-500 border-t-transparent flex justify-center items-center">X</div>
    )
  }
interface iLoadingInsideDev {
    backDropOpen?: boolean
    top?:number
}
const LoadingInsideDev  = (props:iLoadingInsideDev) => {
    // props.top = props.top;
    return (
    //     <div
    //     className={`w-full flex fixed h-full phal items-center justify-center top-[0px]`}
    //     style={{ color: '', background: "#80808052", zIndex: 1000}}
    
    //     onClick={()=> {}}
    //   >
    //     <Loading></Loading>
    //   </div>

//<div className="absolute inset-0 bg-gray-500 opacity-50 flex flex-grow items-center justify-center"> <Loading></Loading></div>
<div className="bg-gray-50 flex justify-center items-center  h-full mx-auto my-0"><Loading></Loading></div>
    )
}   

export default LoadingInsideDev;