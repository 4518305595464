import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import FixedBottomNavigation from './components/Navigator';
import { BrowserRouter as Router, Route,Navigate,Routes } from 'react-router-dom';
import LoginPage from './components/Login';
import RegisterPage from './components/Register';
import { useSelector } from 'react-redux';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import BetTransaction from './components/BetTransaction';
// import http from './http/Request';
import { useDispatch } from 'react-redux';
import { logOutAction, loginAction } from './state/action/login';
import ForgotPasswordPage from './components/ForgotPassword';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import RoundList from './components/RoundList';
import RoundDetail from './components/RoundDetail';
import { onSetBetAmount } from './state/action/betBox';
import E404 from './errors/404';
import { ThemeProvider } from '@mui/material';
import Settings from './components/me/Setting';
import { iUserInfo } from './interface/iUser';
import Transaction from './components/Transaction';
import { setIsLoading } from './state/action/app';
import { loadingTimeout } from './util';
import { setUserInfo } from './state/action/user';
import MyAxios from './http/MyAxios';
import Deposit from './components/Deposit';
import Withdrawal from './components/Withdrawal';
import LanuageSwitcher from './components/me/LanuageSwitcher';
import ChangePass from './components/me/ChangePass';
import PrivateRoute from './PrivateRoute';
import Profile from './components/me/Profile';
import UpdateUsername from './components/me/UpdateUser';
import UpdatePhone from './components/me/UpdatePhone';
import Payment from './components/Payment';
function App() {
  const dispatch = useDispatch();
  const loginState = useSelector((state: any) => state.login);
  const betState = useSelector((state: any) => state.betbox);
  const {isAuthenticated} = loginState;
  const http:MyAxios = new MyAxios();
  // const navigate = useNavigate();

  // const OnError = () => {

  // }

  // const onResponse = (res:any) => {
  //   console.log(res,'info');

  // }
  
  // useEffect(() => {
  //   http.get({path: '/user/1',cb: onResponse, OnError: OnError});
  // },[])

  useEffect(() => {
    // dispatch(setIsLoading(true));
    // const OnError = (err:any) => {
    //     console.log(err,'err');
    //     dispatch(logOutAction());
    //     setTimeout(() => {
    //       dispatch(setIsLoading(false));
    //     },loadingTimeout)

    // }
    
    // const onResponse = (res:any) => {
      
    //  const info:iUserInfo = res.data;
    //  console.log(info,'info',res);
    // //  setTimeout(() => {
    //    dispatch(setIsLoading(false));
    // //  },loadingTimeout)
    //   if(info) {
    //     dispatch(setUserInfo(info));
    //     dispatch(loginAction(true));
    //   } else {
    //     dispatch(logOutAction());
    //   }

    // }

    // localStorage.getItem("token") && http.pos({path: '/user/verify',data: {},cb: onResponse, OnError: OnError}); 
  },[])


  return (
    <I18nextProvider i18n={i18n}>
      <div className="App">
        
        <Router>
          <Routes>
            <Route path="/login" element={<LoginPage/>} />
            <Route path="/register" element={<RegisterPage/>} />
            <Route path="/forgot-pass" element={<ForgotPasswordPage/>} />
            <Route path="/" element={<FixedBottomNavigation/>} />
            {/* <Route path="bet-trx" element={<PrivateRoute  component={<BetTransaction />}  />} /> */}
            <Route element={<PrivateRoute/>}>
                <Route path="dep-with-trx" element={<Transaction />} />
                <Route path="round-list" element={<RoundList />} />
                <Route path="round-detail" element={<RoundDetail />} />
                <Route path="setting" element={<Settings />} />
                <Route path="profile" element={<Profile />} />
                <Route path="update-u" element={<UpdateUsername />} />
                <Route path="update-phone" element={<UpdatePhone />} />
                <Route path="dep" element={<Deposit />} />
                <Route path="withdraw" element={<Withdrawal />} />
                <Route path="language" element={<LanuageSwitcher />} />
                <Route path="change-pass" element={<ChangePass />} />
                <Route path="payment" element={<Payment />} />
                <Route  path="bet-trx" element={<BetTransaction />} ></Route>
            </Route>
           
         
          </Routes>
        </Router>
    </div>
    </I18nextProvider>
  );

}

// const PrivateRoute: React.FC<{ component?: React.ReactElement}> = ({
//   component,
// }) => { 
//   const loginState = useSelector((state: any) => state.login);
//   const app = useSelector((state: any) => state.app);
//   const {isAuthenticated} = loginState;
//    if(component) {
//     return isAuthenticated  ? component : <LoginPage></LoginPage>;
//    } else {
//     return isAuthenticated ? <FixedBottomNavigation/> :<LoginPage></LoginPage>;
//    }
  
// };

export default App;
