import { UPDATE_LOGIN, LOGOUT } from '../actionType/login';
interface AppState {
  isAuthenticated: boolean;
}

const initialState: AppState = {
  isAuthenticated: !!localStorage.getItem('token'),
};

const Login = (
  state: AppState = initialState,
  action: UpdateLoginAction,
): AppState => {
  switch (action.type) {
    case UPDATE_LOGIN:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

export default Login;
