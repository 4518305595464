const keys = ['phone',"password"] as const;
const  phoneValidate  = (value:any,cb:any) =>{
    if(!value) {
        cb((errors:any) => {
          return {
            ...errors,
            phone: true
          }
        });
  
      } else {
        cb((errors:any) => {
          return {
            ...errors,
            phone: false
          }
        });
      }
}

const passwordValidate  = (value:any,cb:any) =>{
    if(!value) {
        cb((errors:any) => {
          return Object.assign(errors,{password:true})
        });
  
      } else {
        cb((errors:any) => {
          return {
            ...errors,
            password: false
          }
        });
      }
}

const smsCodeValidate  = (value:any,cb:any) =>{
    if(!value) {
        cb((errors:any) => {
          return {
            ...errors,
            code: true
          }
        });
  
      } else {
        cb((errors:any) => {
          return {
            ...errors,
            code: false
          }
        });
      }
}

const validateByKey  = (key:string,value:any,cb:any) => {
  let obj:any  = {};
  
  if(!value) {
    
      cb((errors:any) => {
        obj[key] = true;
        return Object.assign(errors,obj)
      });

    } else {
      cb((errors:any) => {
        obj[key] = false;
        return Object.assign(errors,obj)
      });
    }
}



export {phoneValidate,passwordValidate,smsCodeValidate,validateByKey}