import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../Header";
import BackButton from "../BackButton";
import { FormControl, InputLabel, FilledInput, InputAdornment, IconButton, Button, Input } from "@mui/material";
import { Visibility,VisibilityOff } from "@mui/icons-material";
import { ChangeEvent, useState } from "react";
import  locker from "../../assets/locker.jpg";
import { mainColor } from "../../color";
import { iUserInfo } from "../../interface/iUser";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updatePhone } from "../../state/action/user";
import MyToastContainer from "../MyToastContainer";
import { toast } from 'react-toastify';
import MyAxios from "../../http/MyAxios";
const UpdatePhone = (props:any) => {
    const {t} = useTranslation();
    const userState:iUserInfo = useSelector((state:any) => (state.userState));
    const [phone,setPhone] = useState(userState.phone);
    const dispatch = useDispatch();
    const http:MyAxios = new MyAxios()
    const onUpdate = () => {

        if(phone == null) {toast("enter phone");return;};
        http.patch({path: "/user/1",data: {phone: phone.toString()},cb: (e:any) => {
            if(e.status == 1) {
                dispatch(updatePhone(phone.toString()));
                toast(e.message);
            } else {
                toast(e.message);
            }
        }});
    }
    return (
        <>
        <Header title={t('phone')} back={<BackButton></BackButton>}></Header>
        <div className="flex flex-col">
            <FormControl sx={{ m: 1,}} variant="filled">
            {/* <InputLabel htmlFor="filled-adornment-password">{t("phone")}</InputLabel> */}
            <Input
                id="phone"
                type="number"
                value={phone}
                className="pl-2"
                onChange={(e:ChangeEvent<HTMLInputElement>) => {setPhone(+e.target.value)}}
            />
            </FormControl>

            <div onClick={onUpdate} className={`px-4 w-32 mt-6 rounded-lg mx-auto bg-[${mainColor}] py-2 text-white border-[1px] border-solid shadow-sm shadow-[${mainColor}]`}>Submit</div>
        </div>
        <MyToastContainer></MyToastContainer>
        </>

    )
}

export default UpdatePhone;