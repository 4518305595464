import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../Header";
import BackButton from "../BackButton";
import i18n from "../../i18n";
import { useState } from "react";
const LanuageSwitcher = () => {
    const navigate = useNavigate();
    const [activeLang,setActiveLang] = useState(i18n.language);
    let langs = ['kh','en']
    const changeLanguage = (lang: string) => {
        setActiveLang(lang);
        i18n.changeLanguage(lang);
      };
    

    const {t} = useTranslation();

    return (
        <>
        <Header title={t('language')} back={<BackButton></BackButton>}></Header>
        <div className="flex flex-col">
            {
                langs.map((lang:string) => ( <button  onClick={() => {changeLanguage(lang)}} className={`flex mx-4 justify-between px-4 py-3 mt-2 shadow-sm rounded-lg border ${lang == activeLang ? "bg-[#FB0605]" : ""}`}>{t(lang)}</button>))
            }
        </div>
        </>

    )
}

export default LanuageSwitcher;