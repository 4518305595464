// reducers.ts
import { setIsLoading } from '../action/app';
interface AppState {
  isLoading: boolean;
}

const initialState: AppState = {
  isLoading: true,
};

type AppAction = ReturnType<typeof setIsLoading>;

const appReducer = (
  state: AppState = initialState,
  action: AppAction,
): AppState => {
  switch (action.type) {
    case 'SETISLOADING':
      return {
        ...state,
        isLoading: action.value,
      };
    default:
      return state;
  }
};

export default appReducer;
