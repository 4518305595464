import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from 'axios';
interface Get {
  path: string;
  query?: any;
  cb: Function;
  OnError?: Function;
}

interface Pos {
  path: string;
  data: any;
  cb: Function;
  headers?: any,
  OnError?: Function;
}

class MyAxios {
  apiUrl = process.env.REACT_APP_API_URL;
  token = localStorage.getItem('token'); // Replace with your actual token
  axiosInstance: AxiosInstance;
  OnError() {}
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_URL + '/', // Replace with your API base URL
      timeout: 5000, // Set a default timeout (milliseconds)
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.token}`,
      },
      withCredentials: true, // Enable sending credentials with cross-origin requests
    });

    // this.axiosInstance.interceptors.request.use(
    //   (config: AxiosRequestConfig) => {
    //     // Modify the request config before it is sent
    //     // For example, add authentication token or other headers
    //     return config;
    //   },
    //   (error: AxiosError) => {
    //     // Handle request errors
    //     return Promise.reject(error);
    //   }
    // );

    // Add response interceptors
    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => {
        // Modify the response data before it is resolved
        return response;
      },
      (error: AxiosError) => {
        // Handle response errors
        this.OnError();
        return Promise.reject(error);
      },
    );
  }

  async get({ path, query, cb }: Get) {
    console.log('get', this.axiosInstance.get);

    const response = await this.axiosInstance.get(path, { params: query });
    response && response.data && cb(response.data);
    // Handle the response data
    console.log('Response:', response.data);
  }

  async pos({ path, data, cb, OnError,headers }: Pos) {
    try {
      console.log({headers},'headers');
      const response = await this.axiosInstance.post(path, data , headers && {headers});

      response && response.data && cb(response.data);

      // Handle the response data
      console.log('Response:', response.data);
    } catch (err: any) {
      OnError && OnError(err.response.data);
    }
  }

  async patch({ path, data, cb }: Pos) {
    const response = await this.axiosInstance.patch(path, data);
    response && response.data && cb(response.data);

    // Handle the response data
    console.log('Response:', response.data);
  }
  async delete({ path, query, cb }: Get) {
    console.log('delete', this.axiosInstance.delete);

    const response = await this.axiosInstance.delete(path);
    response && response.data && cb(response.data);
    // Handle the response data
    console.log('Response:', response.data);
  }
}

export default MyAxios;
