export const onSetBetAmount = (value: number) => ({
  type: 'ONSELECT' as const,
  data: value,
});

export const onSetChannel = (value: string[]) => ({
  type: 'ONSELECTCHANNEL' as const,
  data: value,
});

export const onSetLottoType = (value: number) => ({
  type: 'ONSELECTLOTTOTYPE' as const,
  data: value,
});

export const onSetBetTimes = (value: number) => ({
  type: 'ONSETBETTIMES' as const,
  data: value,
});

export const onUpdateBetTimes = (value: number) => ({
  type: 'ONUPDATEBETTIMES' as const,
  data: value,
});
