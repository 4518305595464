import Backdrop from '@mui/material/Backdrop';
const Loading =  () => {
    return (
      <div className="w-12 h-12 z-30 rounded-full animate-spin border-x-2 border-dashed border-blue-500 border-t-transparent flex justify-center items-center">X</div>
    )
  }
interface iHttpLoading {
    backDropOpen: boolean
}
const HttpLoading  = (props:iHttpLoading) => {
    return (
        <Backdrop
        sx={{ color: '', background: "white", zIndex: (theme:any) => theme.zIndex.drawer + 1 }}
        open={props.backDropOpen}
        onClick={()=> {}}
      >
        <Loading></Loading>
      </Backdrop>
    )
}   

export default HttpLoading;