interface iKeyPadState {
  results: number[];
}

const initialState: iKeyPadState = {
  results: [],
};

const KeyPadState = (state: iKeyPadState = initialState, action: any) => {
  switch (action.type) {
    case 'UPDATERESULT':
      return {
        ...state,
        results: [...state.results, action.data],
      };

    case 'SETRESULT':
      return {
        ...state,
        results: action.data,
      };

    case 'CLEARRESULT': {
      return {
        ...state,
        results: [],
      };
    }
    default:
      return state;
  }
};

export default KeyPadState;
