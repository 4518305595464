import { MouseEventHandler } from "react"
import { LottoType, formatCurrency, formatCurrencyDecimal, ntxType, stringBetStatus, transactionStatus, txType } from "../util"
import moment from "moment"
import { useDispatch } from "react-redux"
import { dateFormate } from "../util"
import { mainColor } from "../color"
import { useTranslation } from "react-i18next"

interface iTransactionDetail {
    data?:any,
    formType: string,
    
    onCloseDetail: MouseEventHandler
}


const TransactionDetail = (props:iTransactionDetail) => {
    let data = props.data;
    let formType =  props.formType;
    const {t} = useTranslation();
    const dispatch = useDispatch();

    return (
        <div className={`fixed flex flex-col w-full h-[500px] z-20 bottom-0 bg-[${mainColor}]`}>
            <button onClick={props.onCloseDetail} className="flex m-2  rounded-full bg-gray-400 w-10 h-10 text-white justify-center items-center">X</button>
            <div className="h-[400pxw-full rounded-xl">
                <div className="text-3xl py-16 text-green-500"><span>KHR</span> {formatCurrencyDecimal(data.amount)} </div>
                <div className="px-8">
                    <div className="flex justify-between border-b-[1px] border-b-gray-400 py-2 text-white">
                            <div>TxId</div>
                            <div>{data.tx_id}</div>
                    </div>
                    <div className="flex justify-between border-b-[1px] border-b-gray-400 py-2 text-white">
                            <div>{t("amount")}</div>
                            <div>{formatCurrency(data.amount)}</div>
                    </div>
                    <div className="flex justify-between border-b-[1px] border-b-gray-400 py-2 text-white">
                            <div>{t("comment")}</div>
                            <div>{data.comment}</div>
                    </div>
                    <div className="flex justify-between border-b-[1px] border-b-gray-400 py-2 text-white">
                            <div>{t("transaction_type")}</div>
                            <div className="capitalize">{formType == 'deposit' ? txType[data.type] : "commision"}</div>
                    </div>
                    <div className="flex justify-between border-b-[1px] border-b-gray-400 py-2 text-white">
                            <div>{t("status")}</div>
                            <div className="capitalize">{t(transactionStatus[data.status])}</div>
                    </div>
                    <div className="flex justify-between border-b-[1px] border-b-gray-400 py-2 text-white">
                            <div>{t("time")}</div>
                            <div>{moment(data.createdAt).format(dateFormate)}</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TransactionDetail;

interface iBetList {
    data: {
        lotto_type: string,
        win_amount: number,
        bet_time: string
    }
}

const BetList = (props:iBetList) => {
    let statusColor = '';
    const data = props.data;
    let win = data.win_amount;
    if(data.win_amount > 0) {
        statusColor = "text-[#50F242]";
    } else if(!data.win_amount) {
        statusColor = "text-[gray]";
    } else {
        statusColor = "text-[#FB0000]";
    }
    return (
        <div className="flex justify-between flex-row py-2 border-[1px] border-solid rounded-xl h-[70px] bg-gradient-to-b from-[#6D0404] to-[#C61010] px-4">
            <div className="w-[55px] h-[55px] rounded-full bg-gradient-to-b from-[#FB0605] to-[#950403] text-white text-2xl font-bold pt-2">{LottoType[+data.lotto_type]}</div>

            <div className="text-lg">
                <div className={`${statusColor}`}>{formatCurrency(win)}</div>
                <div className="text-white">{moment(+data.bet_time * 1000).format("YYYY/MM/DD HH:mm")}</div>
            </div>
        </div>
    )
}