import BackButton from "./BackButton";
import Header from "./Header";
import { useTranslation } from "react-i18next";
import CountdownTimer from "./CountDownTimer";
import Time from "./element/Time";
import ButtonStyled from "./element/ButtonStyles";
import MainContainer from "./element/mainContainer";
import { Link, useLocation, useParams } from "react-router-dom";
import { Button, IconButton } from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { LottoType, UserComm, formatCurrency, loadingTimeout, lockTimeBeforeRound, mainPx } from "../util";
import Backdrop from '@mui/material/Backdrop';
import { useEffect, useState } from "react";
import Keypad1 from "./keypad/KeyPad1";
import { lottoChannel } from "../util";
import BetsBox from "./element/BetsBox";
import { iRoundList } from "../interface/iProduct";
import LoadingSpinner from "./Loading";
// import http from "../http/Request";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { onSetBetTimes, onSetLottoType } from "../state/action/betBox";
import { getUniquePermutations } from "../util";
import { onSetChannel } from "../state/action/betBox";
import MyToastContainer from "./MyToastContainer";
import { iBetRequest } from "../interface/iBet";
import { winningMultiplication } from "../util";
import { toast } from 'react-toastify';
import { lottoTypeLuckyNumLength } from "../util";
import DialogBox from "./element/DialogBox";
import MyAxios from "../http/MyAxios";
import { clearResult } from "../state/action/keypad";
import { updateBalance, updateComm } from "../state/action/user";
import { mainColor } from "../color";
interface iRoundDetail {
    round_id: String,
    product_time: number
}

const RoundDetail = ({ props }: any): JSX.Element => {
    const { t } = useTranslation();
    const location = useLocation();
    const { state } = location;
    const time = new Time();
    const [isLoading, setIsloading] = useState(true);
    const dispatch = useDispatch();
    const allState = useSelector((state: any) => state);
    const { appState } = allState.app;
    const betBox = allState.betbox;
    const { selectedLottoType, totalBetTimes, selectedChannel, betAmount } = betBox;
    const luckyNum = allState.keypad.results;
    const [showLottoList, setToggleTottoList] = useState(false);
    const [isReverse, setIsReverse] = useState(false);
    const [detail, setDetail] = useState<iRoundDetail | null>();
    const [winUpTo, setWinUpTo] = useState<number>(betAmount * winningMultiplication[selectedLottoType] * (totalBetTimes * selectedChannel.length));
    const [openDialog, setOpenDialog] = useState(false);
    const [reverseResult, setreverseResult] = useState<any>();
    const [remainingTime, setRemainingTime] = useState<number>(0);
    const [isClosed, setIsClosed] = useState(false);
    const http: MyAxios = new MyAxios();
    const cb = (res: any) => {
        if (res.data) {
            setRemainingTime(res.data.product_time - (lockTimeBeforeRound) * 60); // 
            setDetail(res.data);
            let timer = setTimeout(() => {
                clearTimeout(timer);
                setIsloading(false);
            }, loadingTimeout)
        }

        console.log(res, 'data');
    }

    const handleOpenDialog = () => {
        if (luckyNum.length <= 0 || luckyNum.length < lottoTypeLuckyNumLength[selectedLottoType]) {
            toast(t("please_select_lucky_num"), { type: "error" }); return;
        }

        if (selectedChannel && selectedChannel.length <= 0) {
            toast(t("please_select_channel"), { type: "error" }); return;
        }

        if (isClosed) { // check if round already close
            toast(t("round_already_closed"), { type: "error" }); return;
        }

        console.log(selectedChannel);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const confirmBet = () => {
        setOpenDialog(false);
        if (allState.userState.balance < (betAmount * totalBetTimes * selectedChannel.length)) { //  check if use have enough balance
            toast(t("no_balance"), { type: "error" }); return;
        }

        onBet();
    }
    function doSomethingAsync(data: iBetRequest, i: any, j: any) {
        console.log(i, 'i', j, "j");
        data.lotto_channel = i;
        data.lucky_num = j;
        return data;
    }
    async function processItems(data: iBetRequest) {
        return new Promise(async (resolve, reject) => {
            try {
                const results = [];

                for (let i = 0; i < selectedChannel.length; i++) {
                    //   const row = data[i];
                    const rowResults = [];

                    for (let j = 0; j < reverseResult.length; j++) {
                        // const item = row[j];
                        // Use a closure to capture the value of 'item' for each iteration
                        const result = await (async (data) => {
                            return await doSomethingAsync(data, selectedChannel[i], [reverseResult[j]]);
                        })(data);
                        rowResults.push(result);
                    }

                    results.push(rowResults);
                }

                resolve(results);
            } catch (error) {
                reject(error);
            }
        });
    }


    async function generateBet(data: iBetRequest) { // generate multi bet from user selection
        //  try {
        //     var bets = [];
        //     for(const i of selectedChannel) {
        //         for (const j of reverseResult) {
        //             let update = await doSomethingAsync(data,i ,j);
        //             bets.push(update);
        //         }
        //     }
        //     return bets;
        //  } catch(e:any) {console.log(e);}


        return new Promise(async (resolve, reject) => {
            try {
                var bets = [];
                for (const i of selectedChannel) {
                    for (const j of reverseResult) {
                        let update = await doSomethingAsync(data, i, j);
                        console.log(update, 'update');
                        bets.push(update);
                    }
                }

                // Once the loop completes, resolve the promise with the results
                resolve(bets);
            } catch (error) {
                reject(error); // Reject the promise if an error occurs
            }
        });

    }
    const resetvalue = () => {
        dispatch(clearResult());
        dispatch(onSetBetTimes(1))
        setIsReverse(false);
        // setreverseResult([]);
    }

    const onBet = async () => {
        if (!detail) return;
        let totalBetAmount = betAmount * totalBetTimes * selectedChannel.length;
        let comm = totalBetAmount * UserComm / 100; // commistion pay to player
        let data: iBetRequest = {
            bet_amount: betAmount,
            round_id: +detail.round_id,
            lotto_channel: selectedChannel.join(","),
            lucky_num: luckyNum.join(","),
            bet_time: Math.round((new Date().getTime() / 1000)).toString(),
            lotto_type: selectedLottoType,
            totalBetTimes: (totalBetTimes * selectedChannel.length),
            is_reverse: isReverse
        }

        console.log('betData', data);
        const onBetResponse = (e: any) => {

            if (e.statusCode == 200) {
                dispatch(updateComm('+', comm));
                dispatch(updateBalance(-totalBetAmount));
                resetvalue();
                toast(t("place_success"), { type: "success" });
            } else {
                toast(t("place_error"), { type: "error" });
            }

        }

        http.pos({ path: 'betorder/make-bet', data: data, cb: onBetResponse });
    }

    useEffect(() => {
        setWinUpTo(betAmount * winningMultiplication[selectedLottoType] * (totalBetTimes * selectedChannel.length));
    }, [selectedChannel, totalBetTimes, selectedLottoType, betAmount])

    useEffect(() => {

        http.get({ path: `/product/${state.id}`, cb: cb });

    }, []);


    const RoundItem = ({ data }: any) => {
        const remainingTimeToShowResult = data.product_time;
        return (
            <>
                {isLoading && <LoadingSpinner></LoadingSpinner>}
                <div className="flex flex-col border-b-[1px]  border-solid">
                    <div className="grid grid-cols-3 ">
                        <div className="flex flex-col">
                            <div>អេសង់</div>
                            <div>{data.round_id}</div>
                        </div>

                        <div className="flex justify-center items-center">
                            <div>{time.getHM(data.product_time).h}</div> :
                            <div>{time.getHM(data.product_time).m}</div>
                        </div>
                        {isClosed ? <div className="flex flex-col justify-center">
                            <CountdownTimer targetTimestamp={remainingTimeToShowResult} cls="text-xl text-red-900 font-bold "></CountdownTimer>
                        </div> : <div className="flex flex-col">
                            <div>ម៉ោងបិទ</div>
                            <CountdownTimer cb={setIsClosed} targetTimestamp={remainingTime}></CountdownTimer>
                        </div>}


                        {/* <div className="flex flex-col justify-center">
                    <ButtonStyled><Link to={''}>{t('play')}</Link></ButtonStyled>
                </div> */}
                    </div>
                    <div className="flex justify-center">
                        {/* <LottoType></LottoType> */}
                    </div>
                </div>
            </>
        )
    }

    const LottoChannelList = () => {
        return (
            <div className="grid grid-cols-3 w-[100%] mb-2 bg-white">
                {LottoType.map((i, index) => (
                    <div key={`lotto-${i}`} onClick={() => {
                        dispatch(onSetLottoType(index));
                        resetvalue();
                        setToggleTottoList(false);
                    }} className={`p-4 border-[1px] border-solid ${index == selectedLottoType ? `bg-[#FB0605] text-white` : ''}`}>{i}</div>
                ))}
            </div>
        )
    }

    const LottoList = () => {
        const handleButtonClick = (value: string) => {
            var updateSelectedLotto: string[];
            if (value == "All") {
                dispatch(onSetChannel(lottoChannel));
            } else if (selectedChannel.includes(value)) {
                updateSelectedLotto = selectedChannel.filter((item: any) => item !== value);
                dispatch(onSetChannel(updateSelectedLotto));
            } else {
                dispatch(onSetChannel([...selectedChannel, value]));
            }

            console.log(selectedChannel, value, 'value', selectedChannel.includes(value));

        }

        return (
            <div className="grid grid-cols-5 gap-2 w-[100%]">
                <div onClick={() => (handleButtonClick("All"))} className={`p-1 border-[1px] border-solid rounded-full  ${selectedChannel.length == lottoChannel.length ? "bg-[#FB0605] text-white" : ""}`}>ALL</div>
                {lottoChannel.map((i, index) => (
                    <div key={`lotto-type-${i}`} onClick={() => (handleButtonClick(i))} className={`p-1 border-[1px] border-solid rounded-full  ${selectedChannel.includes(i) ? `bg-[#FB0605] text-white` : ''}`}>{i}</div>
                ))}
            </div>
        )
    }

    const onClickLottoList = () => {
        setToggleTottoList(!showLottoList);
    }

    const onClickReverse = () => {
        if(selectedLottoType == 2) {// 5D
            toast(t('can_not_reverse_5D')); return;
        }
        
        if (luckyNum.length <= 0 || luckyNum.length < lottoTypeLuckyNumLength[selectedLottoType]) {  // valide if no no lucky can't select
            toast(t("please_select_lucky_num"), { type: "error" }); return;
        }

        setIsReverse(!isReverse);
        let result = getUniquePermutations(luckyNum);
        if (!isReverse) {
            setreverseResult(result);
            dispatch(onSetBetTimes(result.length))
        } else {
            dispatch(onSetBetTimes(1))
        }

    }

    return (
        <>

            <Header back={<BackButton></BackButton>}>
                <div onClick={onClickLottoList}>
                    <span className="text-white">{t("lucky_place")}</span>
                    <div className="text-white text-lg">{allState && formatCurrency(allState.userState.balance)}</div>
                </div>
            </Header>

            <div className="grid">
                {
                    <LottoChannelList></LottoChannelList>
                }

                <div className="flex flex-col gap-2 px-[10px]">
                    <>
                        {detail && <RoundItem data={detail}></RoundItem>}
                    </>
                    <LottoList></LottoList>
                </div>



                <Keypad1 cls={`px-[10px]`}></Keypad1>

                <div className="flex px-[10px] mt-4 border-solid border-b-[1px] justify-start">
                    <button className={`border-[red] border-[1px] px-4 py-1 mb-2 rounded-lg  ${isReverse ? `bg-[${mainColor}] text-white` : `text-[${mainColor}]`} `} onClick={onClickReverse} >Reverse</button>
                </div>
                {/* <div className="flex px-[10px] pb-4 mt-1 border-b-[1px] border-solid justify-end"> <Link className="underline text-[#FB0605]" to={"/bet-trx"}>{t("see_bet_transaction")}</Link></div> */}

                <div className={`grid grid-cols-1 bottom-0  w-full justify-between h-full items-center`}>
                    <BetsBox isClose={isClosed} handleOpenDialog={handleOpenDialog} winUpTo={winUpTo} reverse={20}></BetsBox>
                </div>
                <div className="flex justify-end"> <Link to={"/bet-trx"} className={` rounded-lg mr-1 bg-[${mainColor}] text-white py-2 px-6 mt-2`}>{t("see_bet_transaction")}</Link></div>
            </div>
            <DialogBox handleConfirm={confirmBet} handleClose={handleCloseDialog} isOpen={openDialog}></DialogBox>
            <MyToastContainer></MyToastContainer>
        </>

    )
}

export default RoundDetail;