// import React, { useEffect } from 'react';
// import { Route, RouteProps, useNavigate,Navigate,Outlet } from 'react-router-dom';
// import { useSelector } from 'react-redux'; // Assuming you're using Redux
// import LoadingSpinner from './components/Loading';


// interface PrivateRouteProps {
//   path?: string;
//   element?: React.ReactNode;
// }

// const PrivateRoute: React.FC<PrivateRouteProps> = () => {
//   const { isAuthenticated } = useSelector((state: any) => state.login); // Assuming login state is in 'login' slice
//   console.log(isAuthenticated, 'isAuthenticated');
//   // Handle redirection if not authenticated

//   // const navigate = useNavigate();
//   // useEffect(() => {
//   //   if (!isAuthenticated) {
//   //     navigate('/login');
//   //   }

//   // },[isAuthenticated])

//   // // Render the protected component if authenticated
//   // return <Route path={path} element={element} />;
//   return (
//     isAuthenticated ? <Outlet></Outlet> : localStorage.getItem("token") ? <></> : <Navigate to={'/login'}></Navigate>
//   )

// };

// export default PrivateRoute;


// import React, { useEffect } from 'react';
// import { Navigate, useNavigate, Outlet } from 'react-router-dom';
// import { useSelector } from 'react-redux'; // Assuming you're using Redux
// import LoadingSpinner from './components/Loading';

// interface PrivateRouteProps {
//   path?: string;
//   element?: React.ReactNode;
// }

// const PrivateRoute: React.FC<PrivateRouteProps> = ({ path, element }) => {
//   const { isAuthenticated } = useSelector((state: any) => state.login); // Assuming login state is in 'login' slice
//   const token = localStorage.getItem('token');
//   const navigate = useNavigate();

//   useEffect(() => {
//    path &&  navigate(path);
//     // Optionally handle additional logic on authentication change
//   }, [isAuthenticated]);

//   // Handle redirection based on authentication status
//   if (isAuthenticated) {
//     return <Outlet />;
//   } else if (token) {
//     // Render loading spinner or nothing if authentication status is pending
//     return <LoadingSpinner />;
//   } else {
//     // Redirect to login if not authenticated and no token
//     // Use Navigate component to ensure proper history management
//     return <Navigate to="/login" replace />;
//   }
// };

// export default PrivateRoute;


// PrivateRoute.tsx
import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const PrivateRoute: React.FC = () => {
  const { isAuthenticated } = useSelector((state: any) => state.login); // Adjust according to your state structure
  const location = useLocation();
console.log(isAuthenticated,'isAuthenticated');
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

export default PrivateRoute;

