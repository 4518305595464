// LoginPage.tsx
import React, { MouseEventHandler, useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { TextField, Button, Container, Paper, Typography,InputAdornment } from '@mui/material';
import { useDispatch } from 'react-redux';
import { loginAction } from '../state/action/login';
import { useNavigate } from 'react-router-dom';
import { mainColor } from '../color';
import { useTranslation } from 'react-i18next';
import BackButton from './BackButton';
import ButtonStyled from './element/ButtonStyles';
import { passwordValidate, phoneValidate, smsCodeValidate } from '../validator/RegisterValidator';
import CountDownSecond from './CountDownSecond';
import http from '../http/Request';
import {FormControl, InputLabel, OutlinedInput, IconButton} from '@mui/material';
import { validateByKey } from '../validator/RegisterValidator';
import { Visibility,VisibilityOff } from '@mui/icons-material';
import { toast } from 'react-toastify';
import MyToastContainer from './MyToastContainer';
interface iErrorsForgetPassword {
  phone: boolean,
  code: boolean,
  cfPass: boolean,
  newPass: boolean
}

const LoginPageContainer = styled(Container)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // height: '100vh',
});

const PaperStyled = styled(Paper)({
  padding: '20px',
  width: '300px',
  textAlign: 'center',
});

const TextFieldStyled = styled(TextField)({
  width: '100%',
  marginBottom: '15px',
});

const ForgotPasswordPage: React.FC = () => {
  const {t} = useTranslation();
  const navigate  = useNavigate();
  const dispatch  = useDispatch();
  const [username, setUsername] = useState('');
  const [newPass, setNewPass] = useState('');
  const [cfPass, setCfPass] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [isSent, setIsSent] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [IspassMatch, setIsPassMatch] = useState(true);
  const [errors, setErrors] = useState<iErrorsForgetPassword>({phone: false, code: false,cfPass:false,newPass:false});

  const handlePhoneVerify = () => {

    phoneValidate(username,setErrors);

    if(!username) return;
    
    smsCodeValidate(true,setErrors);
    validateByKey('newPass', true,setErrors);
    validateByKey("cfPass", true,setErrors);
    setIsSent(true);
    console.log('handlePhoneVerify');return;
    // Your login logic here
    dispatch(loginAction(true));
    navigate('/');
    console.log('Username:', username);
    console.log('smsCode:', smsCode);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onResetResponse  = (e:any) => {
    if(e.statusCode == 200) {
      toast(t("rset"),{ type: 'success',onClose: () => navigate('/login')});
    } else {
      toast(t("inval_phone"),{type: "error"});
    }
  }
  
  const handleResetPassword  = () => {
    phoneValidate(username,setErrors);
    // smsCodeValidate(smsCode,setErrors);
    validateByKey('newPass', newPass,setErrors);
    validateByKey("cfPass", cfPass,setErrors);
    setIsPassMatch(newPass.toString() == cfPass.toString()); // check is cf and pass match

    if(!username|| !newPass || !cfPass || !IspassMatch) return;
    let data = {
      phone : username,
      password: newPass
    }

      http.pos({path: "user/reset-pass", data: data,cb: onResetResponse });
  }

  return (
    <>
      <BackButton cls='text-black' sx={{display: 'flex'}}></BackButton>
      <LoginPageContainer className='mt-52'>
      <PaperStyled elevation={3}>
        <Typography variant="h5" gutterBottom>
          {t('forgot_password')}
        </Typography>
          <TextFieldStyled
          error={errors.phone}
            label={t('phone')}
            variant="outlined"
            value={username}
            helperText={errors.phone && t("phone_required")}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextFieldStyled
          error={errors.newPass}
            label={t('new_password')}
            variant="outlined"
            type={showPassword ? 'text' : 'password'}
            value={newPass}
            helperText={errors.newPass && t("required")}
            onChange={(e) => setNewPass(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility />  :<VisibilityOff />}
                </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextFieldStyled
          error={errors.cfPass || !IspassMatch}
            label={t('confirm_password')}
            variant="outlined"
            value={cfPass}
            type={showPassword ? 'text' : 'password'}
            helperText={errors.cfPass && t("required") || !IspassMatch && t("pass_not_match")}
            onChange={(e) => setCfPass(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <Visibility />  :<VisibilityOff />}
                </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextFieldStyled
            error={errors.code}
            helperText={errors.code && t("code_required")}
            label={t('otp_code')}
            variant="outlined"
            value={smsCode}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                 {isSent ? <CountDownSecond cb={setIsSent} seconds={5}></CountDownSecond> :   <ButtonStyled variant='contained' onClick={handlePhoneVerify}>{t('send_code')}</ButtonStyled> }
                </InputAdornment>
              ),
            }}
            onChange={(e) => setSmsCode(e.target.value)}
          />
          <ButtonStyled variant="contained" onClick={handleResetPassword}>
            {t("reset")}
          </ButtonStyled>
      </PaperStyled>
    </LoginPageContainer>
    <MyToastContainer></MyToastContainer>
    </>

  );
};

export default ForgotPasswordPage;
