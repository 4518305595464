import React, { useState, useEffect } from 'react';
import ButtonStyled from './element/ButtonStyles';
const CountDownSecond: React.FC<{ seconds: number,cb:Function }> = ({ seconds,cb }) => {
  const [countdown, setCountdown] = useState(seconds);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown((prevCountdown) => {
        if(prevCountdown == 1) cb(false);
        if (prevCountdown === 0) {
          clearInterval(timer);
          return 0;
        } else {
          return prevCountdown - 1;
        }
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return <ButtonStyled>{countdown} s</ButtonStyled>;
};


export default CountDownSecond;