import { useTranslation } from "react-i18next"

interface iDone {
    text: string
}
const Done  = (props:iDone) => {
    const {t} = useTranslation();

    return (
        <div className=" flex flex-col gap-9 justify-center items-center fixed bg-white w-full h-full top-0 z-10">
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
            <div className=" text-2xl">{props.text}</div>

        </div>
    )
}

export default Done;