import appReducer from './appReducer';
import Login from './login';
import KeyPadState from './keypad';
import { combineReducers } from 'redux';
import BetBoxState from './betBox';
import UserInfo from './user';
const reducer = combineReducers({
  app: appReducer,
  login: Login,
  keypad: KeyPadState,
  betbox: BetBoxState,
  userState: UserInfo,
});

export default reducer;
