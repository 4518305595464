export const keyPadResult = (result: number[] | number) => ({
  type: 'UPDATERESULT' as const,
  data: result,
});

export const setKeyPad = (result: number[] | number) => ({
  type: 'SETRESULT' as const,
  data: result,
});

export const clearResult = () => ({
  type: 'CLEARRESULT' as const,
});
