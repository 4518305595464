import { MouseEventHandler, useEffect, useState } from "react";
import BackButton from "./BackButton";
import Header from "./Header";
import { useTranslation } from "react-i18next";
import { stringBetStatus,walletTab,bonusTab, formatCurrencyDecimal, selectedCurrency, ntransactionStatus, dateFormate, nBonusTxType, ntxType, bonusTxType, loadingTimeout, minimumBonusToTransfer } from "../util";
import { useSelector } from "react-redux";
import NoRecordRound from "./element/NoRecordRound";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { transactionStatus } from "../util";
import TransactionDetail from "./TransactionDetail";
import MyAxios from "../http/MyAxios";
import HttpLoading from "./element/HttpLoading";
import ButtonStyled from "./element/ButtonStyles";
import { mainColor } from "../color";
import LoadingInsideDev from "./LoadingInsideDev";
import FormTranfer from "./element/FormTransfer";
import { toast } from "react-toastify";
import MyToastContainer from "./MyToastContainer";
import { iUserInfo } from "../interface/iUser";
import { iResponse } from "../interface/IAPIResponse";
import { useDispatch } from "react-redux";
import { updateBalance, updateComm } from "../state/action/user";
interface Transaction {

}

type iTransaction = {
    id: number,
    tx_id: string;
    amount: number;
    type: number
    status: number;
    createdAt: number;
  };

const Transaction = ({}:Transaction) : JSX.Element => {
    const {t} = useTranslation();
    const location = useLocation().state;
    const defaultTab = location && location.tab || 'wallet';
    const [active,setActive] = useState(defaultTab);
    const [content,setContent] = useState<[]>([]);
    const  betStatus = ['new','wait','win',"all"];
    const info:iUserInfo = useSelector((state:any) => state.userState)
    const [backDropOpen, setBackDropOpen] = useState(true);
    const [isShowFormTran, setIsShowFormTran] = useState(false);
    const [amountToTransfer, setAmountToTransfer] = useState<number>(0);
    const http = new MyAxios();
    const dispatch = useDispatch();
    const onTab = ({id}:any) => {
        setActive(id);
    }
    
    const showFormTransfer = () => {
        setAmountToTransfer(0);
        setIsShowFormTran(!isShowFormTran);
    }

    const onResponse = (res:iResponse) => {
      
        if(!(res.statusCode == 200))  {
            toast(t("something_went_wrong"), {type: "success"}); return;
        }
        
        let timer = setTimeout(() => {
            clearTimeout(timer);
            setBackDropOpen(false);
            setAmountToTransfer(0);
            dispatch(updateBalance(amountToTransfer));
            dispatch(updateComm('-',amountToTransfer))
            toast(t("transfer_successfully"), {type: "success"})
        } ,loadingTimeout)


    }

    const onSubmitTransfer = () => {
       
        if(amountToTransfer <= 0 ) {
            toast(t("please_enter_amount"));return ;
        }

        if(amountToTransfer > info.comm) {
            toast(t("insufficient_balance"));return ;
        }

        if(amountToTransfer < minimumBonusToTransfer) {
            toast(t("money_to_transfer_at_least") + selectedCurrency + "" +  minimumBonusToTransfer); return;
        }
        

        let data = {
            type: 3,
            payment_id: 1,
            amount: amountToTransfer
        }

        setBackDropOpen(true);

        http.pos({path: 'deposits', data: data, cb: onResponse });

    }

    useEffect(() => {

        // setContent(active);
        setBackDropOpen(true);
        
    }, [active])
    return (
        <>
        <Header cls="w-full z-10 bg-white" title={t('transaction')} back={<BackButton></BackButton>}></Header>
        <div>
           <div className="grid grid-flow-col gap-2 mx-4 mt-4 z-10 ">
            <div onClick={() => { onTab({id: "wallet"})}} className={`rounded-lg p-4 w-full ${active == "wallet" ? "bg-[#FB0605] text-white" : 'bg-red-50'}`}>
                <div>{t("wallet")}</div>
               <div> <div>នៅសល់</div> <span>{selectedCurrency}</span> {formatCurrencyDecimal(info.balance)} </div>
               <div></div>
            </div>
            <div onClick={() => { onTab({id: "bonus"})}} className={`rounded-lg p-4 w-full ${active == "bonus" ? "bg-[#FB0605] text-white" : 'bg-red-50'}`}>
                 <div>{t("bonus")}</div>
               <div> នៅសល់</div>
               <div><span>KHR</span> {formatCurrencyDecimal(info.comm)}</div>
            </div>
           </div>

           {active == "wallet" ? <Wallet  setBackDropOpen={setBackDropOpen}></Wallet> : <><button onClick={showFormTransfer} className= {` mt-4 px-6 py-2 w-[40%] left-[30%] bg-[${mainColor}] text-white`}>{t('sent_out')}</button> {isShowFormTran && <FormTranfer max={info.comm} min={minimumBonusToTransfer} amount={amountToTransfer} onChange={setAmountToTransfer} onSumbit={onSubmitTransfer}></FormTranfer>} <Bonus setBackDropOpen={setBackDropOpen} ></Bonus></>  }
           <HttpLoading backDropOpen={backDropOpen}></HttpLoading>
        </div>
        <MyToastContainer></MyToastContainer>
        </>

    )
}

interface iwallet {
    setBackDropOpen: Function
}

const Wallet = (props:iwallet) => {
    const http = new MyAxios();
    const {t} = useTranslation();
    const [active,setActive] = useState("deposit");
    const [detail,setDetail] = useState<iTransaction | null>();
    const [content,setContent] = useState<iTransaction[]>([]);
    const [OpenedDetailId, setOpenedDetailId] = useState<number | null>();
    const [backDropOpen, setBackDropOpen] = useState(true);
    const onTab = ({id}:any) => {
        setBackDropOpen(true);
        setActive(id);
        setContent([]);
        http.get({path: "/deposits", query: {tx_type: ntxType[id]},cb: onResponse});
    }

    const onResponse = (res:any) => {
      
        let timer = setTimeout(() => {
            clearTimeout(timer);
            setBackDropOpen(false);
            props.setBackDropOpen(false);
            let data = res.data;
            if(res.statusCode == 200) {
                setContent(data);
                
            } else {
                console.log("no");
            }
        }, loadingTimeout)
    }


    useEffect(() => {
        http.get({path: "/deposits", query: {tx_type: ntxType[active]},cb: onResponse});
    }, [])

    const onCloseDetail = () => {
        setOpenedDetailId(null);
        setDetail(null);
    }

    const onOpenDetail = ({id}:any) => {
        let d = content.filter((item) => (item.id == id));
        d && setDetail(d[0]);
        setOpenedDetailId(id);
    }

    return (
        <>
            <div className="grid grid-flow-col mt-4 w-full z-10 bg-white">
                { walletTab && walletTab.map((status:string) => (<div className={`py-4 border-y-[1px] border-solid ${active.toLocaleLowerCase() == status.toLocaleLowerCase() ? 'text-red-600 border-b-red-600' : ''}`} onClick={() => { onTab({id: status})}} key={status}>{t(`${status}`)}</div>))  }
            </div>
           
            <div className="w-full overflow-y-scroll" style={{height: "calc(100vh - 252px)"}}> {backDropOpen && <LoadingInsideDev></LoadingInsideDev>} {content && content.length > 0  ?  <TransactionContent backDropOpen={backDropOpen} onOpenDetail={onOpenDetail} data={content}></TransactionContent> : <NoRecordRound></NoRecordRound>}</div>
            
            {detail && <TransactionDetail formType="deposit" data={detail} onCloseDetail={onCloseDetail}></TransactionDetail>}
        </>

    )
}

interface iBonus {
    setBackDropOpen: Function
}

const Bonus = (props:iBonus) => {
    const http = new MyAxios();
    const {t} = useTranslation();
    const [active,setActive] = useState("in");
    const [content,setContent] = useState<iTransaction[]>([]);
    const [detail,setDetail] = useState<iTransaction | null>();
    const [OpenedDetailId, setOpenedDetailId] = useState<number | null>();
    const [backDropOpen, setBackDropOpen] = useState(true);
    const onTab = ({id}:any) => {
        setBackDropOpen(true);
        setActive(id);
        http.get({path: "/bonus", query: {tx_type: nBonusTxType[id],limit: 20},cb: onResponse});

    }

    const onCloseDetail = () => {
        setOpenedDetailId(null);
        setDetail(null);
    }
    
    const onOpenDetail = ({id}:any) => {
        let d = content && content.filter((item) => (item.id == id));
        d && setDetail(d[0]);
        setOpenedDetailId(id);
    }

    const onResponse = (res:any) => {
        
        let timer = setTimeout(() => {
            clearTimeout(timer);
            setBackDropOpen(false);
            props.setBackDropOpen(false);
            let data = res.data;
            if(res.statusCode == 200) {
                setContent(data);
                
            } else {
                console.log("no");
            }

        }, loadingTimeout);
    }
    
    useEffect(() => {
        http.get({path: "/bonus", query: {tx_type: nBonusTxType[active], limit: 20},cb: onResponse});
    }, [])

    return (
        <div className="">
           
            <div className="grid grid-flow-col mt-4 w-full z-10 bg-white">
               
                { bonusTab  && bonusTab.map((status:string) => (<div className={`py-4 border-y-[1px] border-solid ${active.toLocaleLowerCase() == status.toLocaleLowerCase() ? 'text-red-600 border-b-red-600' : ''}`} onClick={() => { onTab({id: status})}} key={status}>{t(`${status}`)}</div>))  }
            </div>

            <div className="w-full overflow-y-scroll" style={{height: "calc(100vh - 312px)"}}>{backDropOpen && <LoadingInsideDev></LoadingInsideDev>} {content && content.length ?  <BonusTransactionContent backDropOpen={backDropOpen} onOpenDetail={onOpenDetail} type={active} data={content}></BonusTransactionContent> : <NoRecordRound></NoRecordRound>}</div>
            {detail && <TransactionDetail formType="bonus"  data={detail} onCloseDetail={onCloseDetail}></TransactionDetail>}
        </div>

    )
}

interface iBetContent {
    data?: any,
    type? : string
    onOpenDetail: Function
    backDropOpen:boolean
}


const TransactionContent = (props:iBetContent) => {
    const {t} = useTranslation();
    let data = props.data;
    return (
      
        <>
        <div className="flex flex-col mx-4 z-10 bg-white">
            {data && data.map((item:iTransaction) => (
                            <div key={item.tx_id} onClick={() => { props.onOpenDetail({id: item.id})}} className="flex flex-row justify-between py-2 px-6 border-b-[1px] border-solid rounded-md">
                            {/* <div>3D</div> */}
                            <div>
                                <div className="">{item.tx_id}</div>
                                <div className="text-sm text-gray-600">{moment(item.createdAt).format(dateFormate)}</div>
                            </div>
                            <div>
                                <div><span>KHR </span>{formatCurrencyDecimal(item.amount)}</div>
                                <div className={` text-sm ${item.status == ntransactionStatus["rejected"] ? "text-red-600" : item.status == ntransactionStatus['pending'] ? "text-blue-500" : "text-green-500"}`}>{t(transactionStatus[item.status])}</div>
                            </div>
                        </div>
            ))}

         
          
        </div>
        <Link className="py-2 fixed bg-[#FB0605] bottom-0 rounded-lg w-full left-0 text-white" to={"https://t.me/k24hlotto"}>{t('contact_admin')}</Link>
        </>
    )
}

const BonusTransactionContent = (props:iBetContent) => {
    const {t} = useTranslation();
    let data = props.data;
    let type = props.type;
    return (
      
        <>
        
         <div className="flex flex-col mx-4 z-10 bg-white">
            {data && data.map((item:iTransaction) => (
                            <div key={item.tx_id} onClick={() => { props.onOpenDetail({id: item.id})}} className="flex flex-row justify-between py-2 px-6 border-b-[1px] border-solid rounded-md">
                            {/* <div>3D</div> */}
                            <div>
                                <div className="">{item.tx_id}</div>
                                <div className="text-sm text-gray-600">{moment(item.createdAt).format(dateFormate)}</div>
                            </div>
                            <div className="flex items-center">
                                <div className={`${type == "in" ? "text-green-400" : type == "all" ? bonusTxType[item.type] == "in" ? "text-green-400" : "text-red-600" : "text-red-600"}`}>{bonusTxType[item.type] == "in" ? "+" : "-"}<span>KHR </span>{formatCurrencyDecimal(item.amount)}</div>
                                {/* <div className={` text-sm ${item.status == ntransactionStatus["rejected"] ? "text-red-600" : item.status == ntransactionStatus['pending'] ? "text-blue-500" : "text-green-500"}`}>{t(transactionStatus[item.status])}</div> */}
                            </div>
                        </div>
            ))}

        </div>
        </>
    )
}

export default Transaction;